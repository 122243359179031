import { defineStore } from 'pinia'
import { GiftApi } from '@/utils/api'
import type { Option } from '@/utils/types/Option'
import type { IGiftEventFilter, IGiftEventList, IGiftEvent, IGiftForm } from '@/utils/types'
const api = new GiftApi()

interface StoreState {
  gifts: IGiftEventList
  gift: IGiftEvent
  form: IGiftForm
}

export const useGiftStore = defineStore('giftStore', {
  state: (): StoreState => {
    return {
      gifts: {
        top: [],
        list: {
          data: [],
          meta: {
            last_page: 0,
            from: 0,
            per_page: 0,
            current_page: 0,
            to: 0,
            total: 0
          },
          links: {
            first: '',
            last: '',
            self: ''
          }
        }
      },
      gift: {} as IGiftEvent,
      form: {
        items: [],
        gift_event_id: '',
        sale_person: '',
        channel_id: '',
        receive_name: '',
        receive_phone: '',
        receive_zip_code: '',
        receive_city: '',
        receive_town: '',
        receive_address: '',
        invoice_date: '',
        invoice_number: '',
        invoice_amount: 0,
        invoice_products: '',
        scan_invoice_date: '',
        scan_invoice_number: '',
        scan_invoice_amount: 0,
        scan_invoice_products: '',
        medias: '',
        gift_text: '',
        google_recaptcha_token: ''
      }
    }
  },
  actions: {
    async fetchGiftEvents(f?: IGiftEventFilter) {
      try {
        const res = await api.getGiftEvents(f)
        this.gifts = res.data

        return res.data
      } catch (error) {
        console.error('取得贈品活動列表資料失敗:', error)
      }
    },
    async fetchGiftEvent(id: string) {
      try {
        const res = await api.getGiftEvent(id)
        this.gift = res.data
      } catch (error) {
        console.log('取得贈品活動資料失敗:', error)
      }
    }

    // async postEventSubscribe(params: IEventSubscribe): Promise<boolean> {
    //   const res = await eventApi.submitSubscribeEvent(params)
    //   return res.code === 200
    // }
  },
  getters: {}
})
