import { default as indexIYuJw8iEWxMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/[blogSubCategory]/index.vue?macro=true";
import { default as ClassLink7MxqkwHeCGMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/ClassLink.vue?macro=true";
import { default as Listsy1QJz4ujrAMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/Lists.vue?macro=true";
import { default as TopvNxxF1XONtMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/Top.vue?macro=true";
import { default as indexv00MU8WwLDMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/index.vue?macro=true";
import { default as seo8xYsPhgoYpMeta } from "/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/seo.ts?macro=true";
import { default as ArticleLaXjckQ0cSMeta } from "/home/fable/hengstyle-frontend/pages/blog/article/[slug]/components/Article.vue?macro=true";
import { default as ClassLinkgma7rZJRxpMeta } from "/home/fable/hengstyle-frontend/pages/blog/article/[slug]/components/ClassLink.vue?macro=true";
import { default as index2Mtf0syAXtMeta } from "/home/fable/hengstyle-frontend/pages/blog/article/[slug]/index.vue?macro=true";
import { default as seoIhbHRjDarzMeta } from "/home/fable/hengstyle-frontend/pages/blog/article/[slug]/seo.ts?macro=true";
import { default as indexpbkriVKo45Meta } from "/home/fable/hengstyle-frontend/pages/blog/index.vue?macro=true";
import { default as seovWAXoB034lMeta } from "/home/fable/hengstyle-frontend/pages/blog/seo.ts?macro=true";
import { default as BanneroH7t7vzVRNMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Banner.vue?macro=true";
import { default as BrandsG9krWxnAGWMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Brands.vue?macro=true";
import { default as CardBox84NqY47YxyMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/CardBox.vue?macro=true";
import { default as ChairmanHiYp5hTwIpMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Chairman.vue?macro=true";
import { default as ContactiOxDP3HhKZMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Contact.vue?macro=true";
import { default as ExploreJ7PfPcP6y5Meta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Explore.vue?macro=true";
import { default as MilestoneBRXQgNfs0zMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Milestone.vue?macro=true";
import { default as ObservemOG1KudUdtMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Observe.vue?macro=true";
import { default as ServicecFJ8JZWjaSMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/components/Service.vue?macro=true";
import { default as indexwYgwdUhI3fMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/index.vue?macro=true";
import { default as seo7LYY2XOYDdMeta } from "/home/fable/hengstyle-frontend/pages/brand-story/seo.ts?macro=true";
import { default as _91brandId_93HMY123tv92Meta } from "/home/fable/hengstyle-frontend/pages/brand/[brandId].vue?macro=true";
import { default as BrandActiveXXC9EBLGgXMeta } from "/home/fable/hengstyle-frontend/pages/brand/components/BrandActive.vue?macro=true";
import { default as BrandSlideb2twkZ0ycOMeta } from "/home/fable/hengstyle-frontend/pages/brand/components/BrandSlide.vue?macro=true";
import { default as seoDHsn27Q8sAMeta } from "/home/fable/hengstyle-frontend/pages/brand/seo.ts?macro=true";
import { default as BrandCategoryZ2vlvf2zeOMeta } from "/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandCategory.vue?macro=true";
import { default as BrandListUr7ltSb9xsMeta } from "/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandList.vue?macro=true";
import { default as indexBLVcPki3SaMeta } from "/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/index.vue?macro=true";
import { default as seo2TKF9GOpUzMeta } from "/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/seo.ts?macro=true";
import { default as indexqZi5wJADshMeta } from "/home/fable/hengstyle-frontend/pages/brands/index.vue?macro=true";
import { default as complaints_45infoQEnapYxVvTMeta } from "/home/fable/hengstyle-frontend/pages/complaints-info.vue?macro=true";
import { default as baseHS824Bk5vCMeta } from "/home/fable/hengstyle-frontend/pages/contact/base.vue?macro=true";
import { default as index7cUfMeZ9q8Meta } from "/home/fable/hengstyle-frontend/pages/contact/index.vue?macro=true";
import { default as seokRxYZ6yHFKMeta } from "/home/fable/hengstyle-frontend/pages/contact/seo.ts?macro=true";
import { default as step2uSx1aZibZbMeta } from "/home/fable/hengstyle-frontend/pages/contact/step2.vue?macro=true";
import { default as step3kdsCygRRKJMeta } from "/home/fable/hengstyle-frontend/pages/contact/step3.vue?macro=true";
import { default as ContentOARXYQ78A8Meta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Content.vue?macro=true";
import { default as RadioItemySOXGlCApaMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/RadioItem.vue?macro=true";
import { default as StepSlLOOQRswhMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step.vue?macro=true";
import { default as Step1jPG4sSb8M4Meta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step1.vue?macro=true";
import { default as Step2ctNPbMn144Meta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step2.vue?macro=true";
import { default as Step3l4LMOk34KrMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step3.vue?macro=true";
import { default as Step46A4S4fUlBhMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step4.vue?macro=true";
import { default as indexNMdBxu2OZYMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/index.vue?macro=true";
import { default as seo3Mler0gvhiMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/seo.ts?macro=true";
import { default as ContentiK3FaPMpoxMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Content.vue?macro=true";
import { default as FaqHvnV6r60bPMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Faq.vue?macro=true";
import { default as Side0Y2EYKUMNgMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Side.vue?macro=true";
import { default as SideMobileWYoBPYbqZRMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/components/SideMobile.vue?macro=true";
import { default as SlideUzhNfVecAyMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Slide.vue?macro=true";
import { default as indexhLUVwPvq1fMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/index.vue?macro=true";
import { default as seozfj0Gs0okHMeta } from "/home/fable/hengstyle-frontend/pages/events/[eventId]/seo.ts?macro=true";
import { default as DropRpuErEnFBMMeta } from "/home/fable/hengstyle-frontend/pages/events/components/Drop.vue?macro=true";
import { default as indexk6SZ6yfHuzMeta } from "/home/fable/hengstyle-frontend/pages/events/index.vue?macro=true";
import { default as seoUd5beHDobeMeta } from "/home/fable/hengstyle-frontend/pages/events/seo.ts?macro=true";
import { default as indexgvkEU3eN8hMeta } from "/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/channel-[id]/index.vue?macro=true";
import { default as index7z5o3j9iUyMeta } from "/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/index.vue?macro=true";
import { default as indexoh50DjclujMeta } from "/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/menu-[id]/index.vue?macro=true";
import { default as applyoqmO2FoUn6Meta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/apply.vue?macro=true";
import { default as MobileBottomUVdrHtnYztMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/MobileBottom.vue?macro=true";
import { default as StepoAejNn9wbAMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step.vue?macro=true";
import { default as Step1XzhF9QTBqTMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step1.vue?macro=true";
import { default as Step2YST8wc4wKOMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step2.vue?macro=true";
import { default as Step35MaRkOm9uyMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step3.vue?macro=true";
import { default as Step4XWncW0KA2kMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step4.vue?macro=true";
import { default as indexHXyIiyiah5Meta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/index.vue?macro=true";
import { default as seoizTvFEyFrXMeta } from "/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/seo.ts?macro=true";
import { default as gsaprZVj6SqfJ4Meta } from "/home/fable/hengstyle-frontend/pages/gsap.vue?macro=true";
import { default as Listxt1NszbzNPMeta } from "/home/fable/hengstyle-frontend/pages/hence/[categoryId]/components/List.vue?macro=true";
import { default as index99qBcIm4I0Meta } from "/home/fable/hengstyle-frontend/pages/hence/[categoryId]/index.vue?macro=true";
import { default as seoHyR8jlaVXUMeta } from "/home/fable/hengstyle-frontend/pages/hence/[categoryId]/seo.ts?macro=true";
import { default as _91id_93C2JPoWCJBzMeta } from "/home/fable/hengstyle-frontend/pages/hence/article/[id].vue?macro=true";
import { default as ContentRicJQfnIgaMeta } from "/home/fable/hengstyle-frontend/pages/hence/article/components/Content.vue?macro=true";
import { default as ListSRsLiqDAfAMeta } from "/home/fable/hengstyle-frontend/pages/hence/article/components/List.vue?macro=true";
import { default as seoiWuYgHx95WMeta } from "/home/fable/hengstyle-frontend/pages/hence/article/seo.ts?macro=true";
import { default as _91id_93KScV3qeGeWMeta } from "/home/fable/hengstyle-frontend/pages/hence/author/[id].vue?macro=true";
import { default as AuthorGCv7tfgWotMeta } from "/home/fable/hengstyle-frontend/pages/hence/author/components/Author.vue?macro=true";
import { default as Listv9wHxZuEswMeta } from "/home/fable/hengstyle-frontend/pages/hence/author/components/List.vue?macro=true";
import { default as seoi2bHGtONEMMeta } from "/home/fable/hengstyle-frontend/pages/hence/author/seo.ts?macro=true";
import { default as AdRL3nc9E4LOMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Ad.vue?macro=true";
import { default as Article4FWV7ixA2kMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Article.vue?macro=true";
import { default as AuthorwOh5AGP70gMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Author.vue?macro=true";
import { default as BannerHpIJD80mpbMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Banner.vue?macro=true";
import { default as CardItemr8QN5t0qnFMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/CardItem.vue?macro=true";
import { default as Experience4t41enA7ENMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Experience.vue?macro=true";
import { default as HenceTextA0QfbzK9nYMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/HenceText.vue?macro=true";
import { default as List1h4yp0jVOgVMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/List1.vue?macro=true";
import { default as List2BwjElHFlJxMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/List2.vue?macro=true";
import { default as List3hgK0WTo119Meta } from "/home/fable/hengstyle-frontend/pages/hence/components/List3.vue?macro=true";
import { default as Newsletterqi4CPBIiucMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Newsletter.vue?macro=true";
import { default as PageLinkPJD3EW4WpCMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/PageLink.vue?macro=true";
import { default as Podcastrj3ywMiwqWMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Podcast.vue?macro=true";
import { default as Recipep0cTPgDzQrMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Recipe.vue?macro=true";
import { default as SidegfLp7kxrvSMeta } from "/home/fable/hengstyle-frontend/pages/hence/components/Side.vue?macro=true";
import { default as indexaszdXe0eEkMeta } from "/home/fable/hengstyle-frontend/pages/hence/index.vue?macro=true";
import { default as indexpv7jICQckeMeta } from "/home/fable/hengstyle-frontend/pages/hence/magazine/index.vue?macro=true";
import { default as seo7kLWLijvy5Meta } from "/home/fable/hengstyle-frontend/pages/hence/magazine/seo.ts?macro=true";
import { default as Content8c71KkElMDMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Content.vue?macro=true";
import { default as ListtFfGrOy2lxMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/List.vue?macro=true";
import { default as SlideBuVe8Am7YXMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Slide.vue?macro=true";
import { default as indexUOzuphFKwGMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/index.vue?macro=true";
import { default as seor9nE6uh5I3Meta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/seo.ts?macro=true";
import { default as FilterYtQLLNiMBRMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/components/Filter.vue?macro=true";
import { default as ListBw5P8vAMY3Meta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/components/List.vue?macro=true";
import { default as indexEN1bIUl4m8Meta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/index.vue?macro=true";
import { default as seoHXe5SLksgJMeta } from "/home/fable/hengstyle-frontend/pages/hence/recipe/seo.ts?macro=true";
import { default as seoHnon6vN51uMeta } from "/home/fable/hengstyle-frontend/pages/hence/seo.ts?macro=true";
import { default as BannerDpQQJ8a7VGMeta } from "/home/fable/hengstyle-frontend/pages/hence/topic/components/Banner.vue?macro=true";
import { default as ListBPgCB1WSBLMeta } from "/home/fable/hengstyle-frontend/pages/hence/topic/components/List.vue?macro=true";
import { default as indexTq02oS0Zh1Meta } from "/home/fable/hengstyle-frontend/pages/hence/topic/index.vue?macro=true";
import { default as seoDjaTw4If5QMeta } from "/home/fable/hengstyle-frontend/pages/hence/topic/seo.ts?macro=true";
import { default as AboutxolqhZbZZyMeta } from "/home/fable/hengstyle-frontend/pages/index/components/About.vue?macro=true";
import { default as BannerVideothOuTBR6UhMeta } from "/home/fable/hengstyle-frontend/pages/index/components/BannerVideo.vue?macro=true";
import { default as Section1FMQW7UfbjQMeta } from "/home/fable/hengstyle-frontend/pages/index/components/Section1.vue?macro=true";
import { default as Section2Pwvujo2elfMeta } from "/home/fable/hengstyle-frontend/pages/index/components/Section2.vue?macro=true";
import { default as Section2SlideBrui30zHraMeta } from "/home/fable/hengstyle-frontend/pages/index/components/Section2Slide.vue?macro=true";
import { default as Section37ADz6dpEO4Meta } from "/home/fable/hengstyle-frontend/pages/index/components/Section3.vue?macro=true";
import { default as Section4CQj2hYD7ATMeta } from "/home/fable/hengstyle-frontend/pages/index/components/Section4.vue?macro=true";
import { default as index4OeBWRvavtMeta } from "/home/fable/hengstyle-frontend/pages/index/index.vue?macro=true";
import { default as seozGzR8Ir2zQMeta } from "/home/fable/hengstyle-frontend/pages/index/seo.ts?macro=true";
import { default as indexF2rVXBLOCHMeta } from "/home/fable/hengstyle-frontend/pages/locations/[locationId]/index.vue?macro=true";
import { default as LocationListyEoW1xzbCQMeta } from "/home/fable/hengstyle-frontend/pages/locations/components/LocationList.vue?macro=true";
import { default as LocationPopfQDplY2Cj3Meta } from "/home/fable/hengstyle-frontend/pages/locations/components/LocationPop.vue?macro=true";
import { default as indexGi2ndYqV4cMeta } from "/home/fable/hengstyle-frontend/pages/locations/index.vue?macro=true";
import { default as seo2DT7QrksX0Meta } from "/home/fable/hengstyle-frontend/pages/locations/seo.ts?macro=true";
import { default as Section1gYzcuJjQp2Meta } from "/home/fable/hengstyle-frontend/pages/member/activity/components/Section1.vue?macro=true";
import { default as Section2BtkvqDOX6cMeta } from "/home/fable/hengstyle-frontend/pages/member/activity/components/Section2.vue?macro=true";
import { default as Section32UaULMYZKMMeta } from "/home/fable/hengstyle-frontend/pages/member/activity/components/Section3.vue?macro=true";
import { default as _91no_93CNzaziChUiMeta } from "/home/fable/hengstyle-frontend/pages/member/activity/credit-note/[no].vue?macro=true";
import { default as indexo5WBo8ax13Meta } from "/home/fable/hengstyle-frontend/pages/member/activity/index.vue?macro=true";
import { default as seo7RAmU5LvXtMeta } from "/home/fable/hengstyle-frontend/pages/member/activity/seo.ts?macro=true";
import { default as PopupContentcRcUMcJUj4Meta } from "/home/fable/hengstyle-frontend/pages/member/components/PopupContent.vue?macro=true";
import { default as SideMenuV8oaY4GZfEMeta } from "/home/fable/hengstyle-frontend/pages/member/components/SideMenu.vue?macro=true";
import { default as indexg5Ztp8jyMdMeta } from "/home/fable/hengstyle-frontend/pages/member/edit/index.vue?macro=true";
import { default as seoI731Q6JBwyMeta } from "/home/fable/hengstyle-frontend/pages/member/edit/seo.ts?macro=true";
import { default as indexuMRZFyBkAAMeta } from "/home/fable/hengstyle-frontend/pages/member/index.vue?macro=true";
import { default as _91no_93AInVoN9yEAMeta } from "/home/fable/hengstyle-frontend/pages/member/refund/[no].vue?macro=true";
import { default as Step1L3RmwVxRJwMeta } from "/home/fable/hengstyle-frontend/pages/member/refund/components/Step1.vue?macro=true";
import { default as Step2zIc1DAGaL6Meta } from "/home/fable/hengstyle-frontend/pages/member/refund/components/Step2.vue?macro=true";
import { default as Step3aX1sO3Qf4hMeta } from "/home/fable/hengstyle-frontend/pages/member/refund/components/Step3.vue?macro=true";
import { default as seoOpXrzqcuBSMeta } from "/home/fable/hengstyle-frontend/pages/member/refund/seo.ts?macro=true";
import { default as seooC0PLiRv5kMeta } from "/home/fable/hengstyle-frontend/pages/member/seo.ts?macro=true";
import { default as Section1SLeFDuUKppMeta } from "/home/fable/hengstyle-frontend/pages/member/service/components/Section1.vue?macro=true";
import { default as Section20LArBBBpeSMeta } from "/home/fable/hengstyle-frontend/pages/member/service/components/Section2.vue?macro=true";
import { default as Section3hoHHx2XXy6Meta } from "/home/fable/hengstyle-frontend/pages/member/service/components/Section3.vue?macro=true";
import { default as _91no_93oUVOQCIyKNMeta } from "/home/fable/hengstyle-frontend/pages/member/service/credit-note/[no].vue?macro=true";
import { default as indexA5HGTP9NdcMeta } from "/home/fable/hengstyle-frontend/pages/member/service/index.vue?macro=true";
import { default as seolxeRlmu3bIMeta } from "/home/fable/hengstyle-frontend/pages/member/service/seo.ts?macro=true";
import { default as membership_45termscJJzY6kiAHMeta } from "/home/fable/hengstyle-frontend/pages/membership-terms.vue?macro=true";
import { default as FaqAYIad4f9C5Meta } from "/home/fable/hengstyle-frontend/pages/membership/components/Faq.vue?macro=true";
import { default as Section1yiaPah5wkUMeta } from "/home/fable/hengstyle-frontend/pages/membership/components/Section1.vue?macro=true";
import { default as Section2xmrW4BV3zXMeta } from "/home/fable/hengstyle-frontend/pages/membership/components/Section2.vue?macro=true";
import { default as Section3UeeqmIpi7oMeta } from "/home/fable/hengstyle-frontend/pages/membership/components/Section3.vue?macro=true";
import { default as Section3ListoSBM27EdiDMeta } from "/home/fable/hengstyle-frontend/pages/membership/components/Section3List.vue?macro=true";
import { default as index8ndW8IZwDpMeta } from "/home/fable/hengstyle-frontend/pages/membership/index.vue?macro=true";
import { default as seohGF9TwZefcMeta } from "/home/fable/hengstyle-frontend/pages/membership/seo.ts?macro=true";
import { default as index49Ky4L3x7VMeta } from "/home/fable/hengstyle-frontend/pages/news/[slug]/index.vue?macro=true";
import { default as _91newsId_93g231vJQKnNMeta } from "/home/fable/hengstyle-frontend/pages/news/article/[newsId].vue?macro=true";
import { default as seolVuOUZmao2Meta } from "/home/fable/hengstyle-frontend/pages/news/article/seo.ts?macro=true";
import { default as ClassLink0Am53Ns0E3Meta } from "/home/fable/hengstyle-frontend/pages/news/components/ClassLink.vue?macro=true";
import { default as ListvBszIYGlouMeta } from "/home/fable/hengstyle-frontend/pages/news/components/List.vue?macro=true";
import { default as NewsItem7JzJ3JiFjzMeta } from "/home/fable/hengstyle-frontend/pages/news/components/NewsItem.vue?macro=true";
import { default as NewsPinjodrhxUcSOMeta } from "/home/fable/hengstyle-frontend/pages/news/components/NewsPin.vue?macro=true";
import { default as indexLgh22nqDS6Meta } from "/home/fable/hengstyle-frontend/pages/news/index.vue?macro=true";
import { default as seozZGajAwTPOMeta } from "/home/fable/hengstyle-frontend/pages/news/seo.ts?macro=true";
import { default as privacy_45policyNAtsemJ0rbMeta } from "/home/fable/hengstyle-frontend/pages/privacy-policy.vue?macro=true";
import { default as qrcodel4KhG1bgs2Meta } from "/home/fable/hengstyle-frontend/pages/qrcode.vue?macro=true";
import { default as scrollKTFH0qEzlmMeta } from "/home/fable/hengstyle-frontend/pages/scroll.vue?macro=true";
import { default as searchnFfBLBMS1KMeta } from "/home/fable/hengstyle-frontend/pages/search.vue?macro=true";
import { default as seo_searchIDS5MxfSrqMeta } from "/home/fable/hengstyle-frontend/pages/seo_search.ts?macro=true";
import { default as FeaturesU2fOGx1FVwMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/components/Features.vue?macro=true";
import { default as Step1793erbL77CMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step1.vue?macro=true";
import { default as Step2n9OsWDMMARMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step2.vue?macro=true";
import { default as Step3mpYQ8cFlKIMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step3.vue?macro=true";
import { default as Step4H84C0OtEgrMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step4.vue?macro=true";
import { default as form_seo7QPXjGqNX9Meta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/form_seo.ts?macro=true";
import { default as formaHgjYsEldCMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/form.vue?macro=true";
import { default as indexVMkHHFJMQmMeta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/index.vue?macro=true";
import { default as seohOWeaSyFG7Meta } from "/home/fable/hengstyle-frontend/pages/services/cleaning/seo.ts?macro=true";
import { default as Bottomd5y5vw2sx2Meta } from "/home/fable/hengstyle-frontend/pages/services/components/Bottom.vue?macro=true";
import { default as CategoryD8wD3aY1QGMeta } from "/home/fable/hengstyle-frontend/pages/services/components/Category.vue?macro=true";
import { default as CategoryItemwwjWcLbHO1Meta } from "/home/fable/hengstyle-frontend/pages/services/components/CategoryItem.vue?macro=true";
import { default as ContactInfod42uvBq0c9Meta } from "/home/fable/hengstyle-frontend/pages/services/components/ContactInfo.vue?macro=true";
import { default as FaqzXTAZ53bZnMeta } from "/home/fable/hengstyle-frontend/pages/services/components/Faq.vue?macro=true";
import { default as FeaturesyoGwuLwwy6Meta } from "/home/fable/hengstyle-frontend/pages/services/components/Features.vue?macro=true";
import { default as OtherdojKP2LfyCMeta } from "/home/fable/hengstyle-frontend/pages/services/components/Other.vue?macro=true";
import { default as ProcessMrePrpoerDMeta } from "/home/fable/hengstyle-frontend/pages/services/components/Process.vue?macro=true";
import { default as Review8RUqIjGHCOMeta } from "/home/fable/hengstyle-frontend/pages/services/components/Review.vue?macro=true";
import { default as Relatedf8UH7zHRKvMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/components/Related.vue?macro=true";
import { default as indexzIcyrkU8AIMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/index.vue?macro=true";
import { default as seoUTp8z8Se3NMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/seo.ts?macro=true";
import { default as ContactBRGtSvQCnDMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/components/Contact.vue?macro=true";
import { default as FaqItemypJ7BXK5zwMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/components/FaqItem.vue?macro=true";
import { default as indexo1YoMcPDlQMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/index.vue?macro=true";
import { default as seos9OFMsT3MGMeta } from "/home/fable/hengstyle-frontend/pages/services/faq/seo.ts?macro=true";
import { default as ContactInfoLBAyDFJMS1Meta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/components/ContactInfo.vue?macro=true";
import { default as Step1sIcGGCG02FMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step1.vue?macro=true";
import { default as Step22577LEisTFMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step2.vue?macro=true";
import { default as Step3Y3sJVAmKU4Meta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step3.vue?macro=true";
import { default as Step4c49pIEOL95Meta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step4.vue?macro=true";
import { default as form_seokSd8F77ynqMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/form_seo.ts?macro=true";
import { default as formARnBlMRjbVMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/form.vue?macro=true";
import { default as indexoCtutQ8t8LMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/index.vue?macro=true";
import { default as seo3cslucX07uMeta } from "/home/fable/hengstyle-frontend/pages/services/fast-repair/seo.ts?macro=true";
import { default as indexE7bhYqzJRZMeta } from "/home/fable/hengstyle-frontend/pages/services/index.vue?macro=true";
import { default as Step1YaFBXU8Y2eMeta } from "/home/fable/hengstyle-frontend/pages/services/inhome/components/Step1.vue?macro=true";
import { default as Step2IMfYLpgmD6Meta } from "/home/fable/hengstyle-frontend/pages/services/inhome/components/Step2.vue?macro=true";
import { default as form_seoTFpbPzLYBVMeta } from "/home/fable/hengstyle-frontend/pages/services/inhome/form_seo.ts?macro=true";
import { default as formcwEsSmvKr1Meta } from "/home/fable/hengstyle-frontend/pages/services/inhome/form.vue?macro=true";
import { default as index7HdiW7KwBCMeta } from "/home/fable/hengstyle-frontend/pages/services/inhome/index.vue?macro=true";
import { default as seoDHAbCytt5FMeta } from "/home/fable/hengstyle-frontend/pages/services/inhome/seo.ts?macro=true";
import { default as Fast4hW1tQoIRqMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/components/Fast.vue?macro=true";
import { default as Step1DdwFIPfanUMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/components/Step1.vue?macro=true";
import { default as Step2Ky77Y0wSXxMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/components/Step2.vue?macro=true";
import { default as Step3cEWvGyI99oMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/components/Step3.vue?macro=true";
import { default as Step4r7lfqTNrsGMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/components/Step4.vue?macro=true";
import { default as form_seoZIaCXGo282Meta } from "/home/fable/hengstyle-frontend/pages/services/repair/form_seo.ts?macro=true";
import { default as form6JoTyaMYklMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/form.vue?macro=true";
import { default as indexN37owyhlGLMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/index.vue?macro=true";
import { default as seoOSxBceCemjMeta } from "/home/fable/hengstyle-frontend/pages/services/repair/seo.ts?macro=true";
import { default as indexq3Ux3BBbklMeta } from "/home/fable/hengstyle-frontend/pages/services/select/index.vue?macro=true";
import { default as seoGGPF1cOPKyMeta } from "/home/fable/hengstyle-frontend/pages/services/select/seo.ts?macro=true";
import { default as seoipsymbKdK1Meta } from "/home/fable/hengstyle-frontend/pages/services/seo.ts?macro=true";
import { default as DirectStep1P4Z2oFUoKiMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/DirectStep1.vue?macro=true";
import { default as DirectStep2HQp08dLI26Meta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/DirectStep2.vue?macro=true";
import { default as Step1xogYvwvzjQMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/Step1.vue?macro=true";
import { default as Step2SdHuJqxRxiMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/Step2.vue?macro=true";
import { default as Step33SBjHj1ZdzMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/Step3.vue?macro=true";
import { default as Step4GCJ1p35eZzMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/components/Step4.vue?macro=true";
import { default as directMgl2IKOXufMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/direct.vue?macro=true";
import { default as form_seoSOBFZA0WsCMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/form_seo.ts?macro=true";
import { default as formk8ixqdjBtJMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/form.vue?macro=true";
import { default as indexuGuSoSC81GMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/index.vue?macro=true";
import { default as seo0zB4SVVJnBMeta } from "/home/fable/hengstyle-frontend/pages/services/warranty/seo.ts?macro=true";
import { default as sitemapifJR7TB0dJMeta } from "/home/fable/hengstyle-frontend/pages/sitemap.vue?macro=true";
import { default as _91subId_93obiZHmdWTKMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/[subId].vue?macro=true";
import { default as BannercQNgpeQ560Meta } from "/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/components/Banner.vue?macro=true";
import { default as ListsdNeQsWFNRGMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/components/Lists.vue?macro=true";
import { default as indexcSgkBr3APfMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/index.vue?macro=true";
import { default as seoYTg5bM8Ua2Meta } from "/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/seo.ts?macro=true";
import { default as ListsqIaosyQayPMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/components/Lists.vue?macro=true";
import { default as indexrYtzSlkngQMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/index.vue?macro=true";
import { default as seoZnnCqcLvVVMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/seo.ts?macro=true";
import { default as Section1q3WNIH6xPvMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section1.vue?macro=true";
import { default as Section2wGyNijzP6qMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section2.vue?macro=true";
import { default as Section3fePhaCRqZnMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section3.vue?macro=true";
import { default as Section49ELsOksjnvMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section4.vue?macro=true";
import { default as Section5ZtmIfZFuUFMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section5.vue?macro=true";
import { default as Section6uytKUV4lAvMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section6.vue?macro=true";
import { default as Section7zQzbV5RAmIMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section7.vue?macro=true";
import { default as Section8rNNINj0Tf1Meta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section8.vue?macro=true";
import { default as Section9cSwgICskSAMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Section9.vue?macro=true";
import { default as SideGddjiG2FYPMeta } from "/home/fable/hengstyle-frontend/pages/sustainability/components/Side.vue?macro=true";
import { default as indexb6ZyNyxxn6Meta } from "/home/fable/hengstyle-frontend/pages/sustainability/index.vue?macro=true";
import { default as seok3GtbcI5m3Meta } from "/home/fable/hengstyle-frontend/pages/sustainability/seo.ts?macro=true";
import { default as Section132nhYw3FHbMeta } from "/home/fable/hengstyle-frontend/pages/talent-development/components/Section1.vue?macro=true";
import { default as Section2a7OYJ8uKXvMeta } from "/home/fable/hengstyle-frontend/pages/talent-development/components/Section2.vue?macro=true";
import { default as Section3ERbOjIO9seMeta } from "/home/fable/hengstyle-frontend/pages/talent-development/components/Section3.vue?macro=true";
import { default as Section4NJbKSwcie4Meta } from "/home/fable/hengstyle-frontend/pages/talent-development/components/Section4.vue?macro=true";
import { default as Section5NFmfEMJmp1Meta } from "/home/fable/hengstyle-frontend/pages/talent-development/components/Section5.vue?macro=true";
import { default as indexktty8EAzGNMeta } from "/home/fable/hengstyle-frontend/pages/talent-development/index.vue?macro=true";
import { default as seowWTotBBwucMeta } from "/home/fable/hengstyle-frontend/pages/talent-development/seo.ts?macro=true";
import { default as BreadcrumbSaAzGnPRduMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Breadcrumb.vue?macro=true";
import { default as Section1u8jf3D4vKTMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section1.vue?macro=true";
import { default as Section2TANFsVBQG0Meta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section2.vue?macro=true";
import { default as Section3JDVsaVcwMmMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section3.vue?macro=true";
import { default as Section4Ztk0N6YFmLMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section4.vue?macro=true";
import { default as Section5p7xzhXdfIJMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section5.vue?macro=true";
import { default as Section6MZPBd9YxBbMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/components/Section6.vue?macro=true";
import { default as indexAI1bQzRyJ4Meta } from "/home/fable/hengstyle-frontend/pages/zonezone/index.vue?macro=true";
import { default as seopDANcarTHnMeta } from "/home/fable/hengstyle-frontend/pages/zonezone/seo.ts?macro=true";
export default [
  {
    name: indexIYuJw8iEWxMeta?.name ?? "blog-blogCategory-blogSubCategory",
    path: indexIYuJw8iEWxMeta?.path ?? "/blog/:blogCategory()/:blogSubCategory()",
    meta: indexIYuJw8iEWxMeta || {},
    alias: indexIYuJw8iEWxMeta?.alias || [],
    redirect: indexIYuJw8iEWxMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/[blogSubCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: ClassLink7MxqkwHeCGMeta?.name ?? "blog-blogCategory-components-ClassLink",
    path: ClassLink7MxqkwHeCGMeta?.path ?? "/blog/:blogCategory()/components/ClassLink",
    meta: ClassLink7MxqkwHeCGMeta || {},
    alias: ClassLink7MxqkwHeCGMeta?.alias || [],
    redirect: ClassLink7MxqkwHeCGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: Listsy1QJz4ujrAMeta?.name ?? "blog-blogCategory-components-Lists",
    path: Listsy1QJz4ujrAMeta?.path ?? "/blog/:blogCategory()/components/Lists",
    meta: Listsy1QJz4ujrAMeta || {},
    alias: Listsy1QJz4ujrAMeta?.alias || [],
    redirect: Listsy1QJz4ujrAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: TopvNxxF1XONtMeta?.name ?? "blog-blogCategory-components-Top",
    path: TopvNxxF1XONtMeta?.path ?? "/blog/:blogCategory()/components/Top",
    meta: TopvNxxF1XONtMeta || {},
    alias: TopvNxxF1XONtMeta?.alias || [],
    redirect: TopvNxxF1XONtMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/components/Top.vue").then(m => m.default || m)
  },
  {
    name: indexv00MU8WwLDMeta?.name ?? "blog-blogCategory",
    path: indexv00MU8WwLDMeta?.path ?? "/blog/:blogCategory()",
    meta: indexv00MU8WwLDMeta || {},
    alias: indexv00MU8WwLDMeta?.alias || [],
    redirect: indexv00MU8WwLDMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: seo8xYsPhgoYpMeta?.name ?? "blog-blogCategory-seo",
    path: seo8xYsPhgoYpMeta?.path ?? "/blog/:blogCategory()/seo",
    meta: seo8xYsPhgoYpMeta || {},
    alias: seo8xYsPhgoYpMeta?.alias || [],
    redirect: seo8xYsPhgoYpMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/[blogCategory]/seo.ts").then(m => m.default || m)
  },
  {
    name: ArticleLaXjckQ0cSMeta?.name ?? "blog-article-slug-components-Article",
    path: ArticleLaXjckQ0cSMeta?.path ?? "/blog/article/:slug()/components/Article",
    meta: ArticleLaXjckQ0cSMeta || {},
    alias: ArticleLaXjckQ0cSMeta?.alias || [],
    redirect: ArticleLaXjckQ0cSMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/article/[slug]/components/Article.vue").then(m => m.default || m)
  },
  {
    name: ClassLinkgma7rZJRxpMeta?.name ?? "blog-article-slug-components-ClassLink",
    path: ClassLinkgma7rZJRxpMeta?.path ?? "/blog/article/:slug()/components/ClassLink",
    meta: ClassLinkgma7rZJRxpMeta || {},
    alias: ClassLinkgma7rZJRxpMeta?.alias || [],
    redirect: ClassLinkgma7rZJRxpMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/article/[slug]/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: index2Mtf0syAXtMeta?.name ?? "blog-article-slug",
    path: index2Mtf0syAXtMeta?.path ?? "/blog/article/:slug()",
    meta: index2Mtf0syAXtMeta || {},
    alias: index2Mtf0syAXtMeta?.alias || [],
    redirect: index2Mtf0syAXtMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/article/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: seoIhbHRjDarzMeta?.name ?? "blog-article-slug-seo",
    path: seoIhbHRjDarzMeta?.path ?? "/blog/article/:slug()/seo",
    meta: seoIhbHRjDarzMeta || {},
    alias: seoIhbHRjDarzMeta?.alias || [],
    redirect: seoIhbHRjDarzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/article/[slug]/seo.ts").then(m => m.default || m)
  },
  {
    name: indexpbkriVKo45Meta?.name ?? "blog",
    path: indexpbkriVKo45Meta?.path ?? "/blog",
    meta: indexpbkriVKo45Meta || {},
    alias: indexpbkriVKo45Meta?.alias || [],
    redirect: indexpbkriVKo45Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: seovWAXoB034lMeta?.name ?? "blog-seo",
    path: seovWAXoB034lMeta?.path ?? "/blog/seo",
    meta: seovWAXoB034lMeta || {},
    alias: seovWAXoB034lMeta?.alias || [],
    redirect: seovWAXoB034lMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/blog/seo.ts").then(m => m.default || m)
  },
  {
    name: BanneroH7t7vzVRNMeta?.name ?? "brand-story-components-Banner",
    path: BanneroH7t7vzVRNMeta?.path ?? "/brand-story/components/Banner",
    meta: BanneroH7t7vzVRNMeta || {},
    alias: BanneroH7t7vzVRNMeta?.alias || [],
    redirect: BanneroH7t7vzVRNMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: BrandsG9krWxnAGWMeta?.name ?? "brand-story-components-Brands",
    path: BrandsG9krWxnAGWMeta?.path ?? "/brand-story/components/Brands",
    meta: BrandsG9krWxnAGWMeta || {},
    alias: BrandsG9krWxnAGWMeta?.alias || [],
    redirect: BrandsG9krWxnAGWMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CardBox84NqY47YxyMeta?.name ?? "brand-story-components-CardBox",
    path: CardBox84NqY47YxyMeta?.path ?? "/brand-story/components/CardBox",
    meta: CardBox84NqY47YxyMeta || {},
    alias: CardBox84NqY47YxyMeta?.alias || [],
    redirect: CardBox84NqY47YxyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/CardBox.vue").then(m => m.default || m)
  },
  {
    name: ChairmanHiYp5hTwIpMeta?.name ?? "brand-story-components-Chairman",
    path: ChairmanHiYp5hTwIpMeta?.path ?? "/brand-story/components/Chairman",
    meta: ChairmanHiYp5hTwIpMeta || {},
    alias: ChairmanHiYp5hTwIpMeta?.alias || [],
    redirect: ChairmanHiYp5hTwIpMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Chairman.vue").then(m => m.default || m)
  },
  {
    name: ContactiOxDP3HhKZMeta?.name ?? "brand-story-components-Contact",
    path: ContactiOxDP3HhKZMeta?.path ?? "/brand-story/components/Contact",
    meta: ContactiOxDP3HhKZMeta || {},
    alias: ContactiOxDP3HhKZMeta?.alias || [],
    redirect: ContactiOxDP3HhKZMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: ExploreJ7PfPcP6y5Meta?.name ?? "brand-story-components-Explore",
    path: ExploreJ7PfPcP6y5Meta?.path ?? "/brand-story/components/Explore",
    meta: ExploreJ7PfPcP6y5Meta || {},
    alias: ExploreJ7PfPcP6y5Meta?.alias || [],
    redirect: ExploreJ7PfPcP6y5Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Explore.vue").then(m => m.default || m)
  },
  {
    name: MilestoneBRXQgNfs0zMeta?.name ?? "brand-story-components-Milestone",
    path: MilestoneBRXQgNfs0zMeta?.path ?? "/brand-story/components/Milestone",
    meta: MilestoneBRXQgNfs0zMeta || {},
    alias: MilestoneBRXQgNfs0zMeta?.alias || [],
    redirect: MilestoneBRXQgNfs0zMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Milestone.vue").then(m => m.default || m)
  },
  {
    name: ObservemOG1KudUdtMeta?.name ?? "brand-story-components-Observe",
    path: ObservemOG1KudUdtMeta?.path ?? "/brand-story/components/Observe",
    meta: ObservemOG1KudUdtMeta || {},
    alias: ObservemOG1KudUdtMeta?.alias || [],
    redirect: ObservemOG1KudUdtMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Observe.vue").then(m => m.default || m)
  },
  {
    name: ServicecFJ8JZWjaSMeta?.name ?? "brand-story-components-Service",
    path: ServicecFJ8JZWjaSMeta?.path ?? "/brand-story/components/Service",
    meta: ServicecFJ8JZWjaSMeta || {},
    alias: ServicecFJ8JZWjaSMeta?.alias || [],
    redirect: ServicecFJ8JZWjaSMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/components/Service.vue").then(m => m.default || m)
  },
  {
    name: indexwYgwdUhI3fMeta?.name ?? "brand-story",
    path: indexwYgwdUhI3fMeta?.path ?? "/brand-story",
    meta: indexwYgwdUhI3fMeta || {},
    alias: indexwYgwdUhI3fMeta?.alias || [],
    redirect: indexwYgwdUhI3fMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/index.vue").then(m => m.default || m)
  },
  {
    name: seo7LYY2XOYDdMeta?.name ?? "brand-story-seo",
    path: seo7LYY2XOYDdMeta?.path ?? "/brand-story/seo",
    meta: seo7LYY2XOYDdMeta || {},
    alias: seo7LYY2XOYDdMeta?.alias || [],
    redirect: seo7LYY2XOYDdMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand-story/seo.ts").then(m => m.default || m)
  },
  {
    name: _91brandId_93HMY123tv92Meta?.name ?? "brand-brandId",
    path: _91brandId_93HMY123tv92Meta?.path ?? "/brand/:brandId()",
    meta: _91brandId_93HMY123tv92Meta || {},
    alias: _91brandId_93HMY123tv92Meta?.alias || [],
    redirect: _91brandId_93HMY123tv92Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand/[brandId].vue").then(m => m.default || m)
  },
  {
    name: BrandActiveXXC9EBLGgXMeta?.name ?? "brand-components-BrandActive",
    path: BrandActiveXXC9EBLGgXMeta?.path ?? "/brand/components/BrandActive",
    meta: BrandActiveXXC9EBLGgXMeta || {},
    alias: BrandActiveXXC9EBLGgXMeta?.alias || [],
    redirect: BrandActiveXXC9EBLGgXMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand/components/BrandActive.vue").then(m => m.default || m)
  },
  {
    name: BrandSlideb2twkZ0ycOMeta?.name ?? "brand-components-BrandSlide",
    path: BrandSlideb2twkZ0ycOMeta?.path ?? "/brand/components/BrandSlide",
    meta: BrandSlideb2twkZ0ycOMeta || {},
    alias: BrandSlideb2twkZ0ycOMeta?.alias || [],
    redirect: BrandSlideb2twkZ0ycOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand/components/BrandSlide.vue").then(m => m.default || m)
  },
  {
    name: seoDHsn27Q8sAMeta?.name ?? "brand-seo",
    path: seoDHsn27Q8sAMeta?.path ?? "/brand/seo",
    meta: seoDHsn27Q8sAMeta || {},
    alias: seoDHsn27Q8sAMeta?.alias || [],
    redirect: seoDHsn27Q8sAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brand/seo.ts").then(m => m.default || m)
  },
  {
    name: BrandCategoryZ2vlvf2zeOMeta?.name ?? "brands-brandCategory-components-BrandCategory",
    path: BrandCategoryZ2vlvf2zeOMeta?.path ?? "/brands/:brandCategory()/components/BrandCategory",
    meta: BrandCategoryZ2vlvf2zeOMeta || {},
    alias: BrandCategoryZ2vlvf2zeOMeta?.alias || [],
    redirect: BrandCategoryZ2vlvf2zeOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandCategory.vue").then(m => m.default || m)
  },
  {
    name: BrandListUr7ltSb9xsMeta?.name ?? "brands-brandCategory-components-BrandList",
    path: BrandListUr7ltSb9xsMeta?.path ?? "/brands/:brandCategory()/components/BrandList",
    meta: BrandListUr7ltSb9xsMeta || {},
    alias: BrandListUr7ltSb9xsMeta?.alias || [],
    redirect: BrandListUr7ltSb9xsMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandList.vue").then(m => m.default || m)
  },
  {
    name: indexBLVcPki3SaMeta?.name ?? "brands-brandCategory",
    path: indexBLVcPki3SaMeta?.path ?? "/brands/:brandCategory()",
    meta: indexBLVcPki3SaMeta || {},
    alias: indexBLVcPki3SaMeta?.alias || [],
    redirect: indexBLVcPki3SaMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: seo2TKF9GOpUzMeta?.name ?? "brands-brandCategory-seo",
    path: seo2TKF9GOpUzMeta?.path ?? "/brands/:brandCategory()/seo",
    meta: seo2TKF9GOpUzMeta || {},
    alias: seo2TKF9GOpUzMeta?.alias || [],
    redirect: seo2TKF9GOpUzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brands/[brandCategory]/seo.ts").then(m => m.default || m)
  },
  {
    name: indexqZi5wJADshMeta?.name ?? "brands",
    path: indexqZi5wJADshMeta?.path ?? "/brands",
    meta: indexqZi5wJADshMeta || {},
    alias: indexqZi5wJADshMeta?.alias || [],
    redirect: indexqZi5wJADshMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: complaints_45infoQEnapYxVvTMeta?.name ?? "complaints-info",
    path: complaints_45infoQEnapYxVvTMeta?.path ?? "/complaints-info",
    meta: complaints_45infoQEnapYxVvTMeta || {},
    alias: complaints_45infoQEnapYxVvTMeta?.alias || [],
    redirect: complaints_45infoQEnapYxVvTMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/complaints-info.vue").then(m => m.default || m)
  },
  {
    name: baseHS824Bk5vCMeta?.name ?? "contact-base",
    path: baseHS824Bk5vCMeta?.path ?? "/contact/base",
    meta: baseHS824Bk5vCMeta || {},
    alias: baseHS824Bk5vCMeta?.alias || [],
    redirect: baseHS824Bk5vCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/contact/base.vue").then(m => m.default || m)
  },
  {
    name: index7cUfMeZ9q8Meta?.name ?? "contact",
    path: index7cUfMeZ9q8Meta?.path ?? "/contact",
    meta: index7cUfMeZ9q8Meta || {},
    alias: index7cUfMeZ9q8Meta?.alias || [],
    redirect: index7cUfMeZ9q8Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: seokRxYZ6yHFKMeta?.name ?? "contact-seo",
    path: seokRxYZ6yHFKMeta?.path ?? "/contact/seo",
    meta: seokRxYZ6yHFKMeta || {},
    alias: seokRxYZ6yHFKMeta?.alias || [],
    redirect: seokRxYZ6yHFKMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/contact/seo.ts").then(m => m.default || m)
  },
  {
    name: step2uSx1aZibZbMeta?.name ?? "contact-step2",
    path: step2uSx1aZibZbMeta?.path ?? "/contact/step2",
    meta: step2uSx1aZibZbMeta || {},
    alias: step2uSx1aZibZbMeta?.alias || [],
    redirect: step2uSx1aZibZbMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/contact/step2.vue").then(m => m.default || m)
  },
  {
    name: step3kdsCygRRKJMeta?.name ?? "contact-step3",
    path: step3kdsCygRRKJMeta?.path ?? "/contact/step3",
    meta: step3kdsCygRRKJMeta || {},
    alias: step3kdsCygRRKJMeta?.alias || [],
    redirect: step3kdsCygRRKJMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/contact/step3.vue").then(m => m.default || m)
  },
  {
    name: ContentOARXYQ78A8Meta?.name ?? "events-eventId-apply-components-Content",
    path: ContentOARXYQ78A8Meta?.path ?? "/events/:eventId()/apply/components/Content",
    meta: ContentOARXYQ78A8Meta || {},
    alias: ContentOARXYQ78A8Meta?.alias || [],
    redirect: ContentOARXYQ78A8Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Content.vue").then(m => m.default || m)
  },
  {
    name: RadioItemySOXGlCApaMeta?.name ?? "events-eventId-apply-components-RadioItem",
    path: RadioItemySOXGlCApaMeta?.path ?? "/events/:eventId()/apply/components/RadioItem",
    meta: RadioItemySOXGlCApaMeta || {},
    alias: RadioItemySOXGlCApaMeta?.alias || [],
    redirect: RadioItemySOXGlCApaMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/RadioItem.vue").then(m => m.default || m)
  },
  {
    name: StepSlLOOQRswhMeta?.name ?? "events-eventId-apply-components-Step",
    path: StepSlLOOQRswhMeta?.path ?? "/events/:eventId()/apply/components/Step",
    meta: StepSlLOOQRswhMeta || {},
    alias: StepSlLOOQRswhMeta?.alias || [],
    redirect: StepSlLOOQRswhMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step.vue").then(m => m.default || m)
  },
  {
    name: Step1jPG4sSb8M4Meta?.name ?? "events-eventId-apply-components-Step1",
    path: Step1jPG4sSb8M4Meta?.path ?? "/events/:eventId()/apply/components/Step1",
    meta: Step1jPG4sSb8M4Meta || {},
    alias: Step1jPG4sSb8M4Meta?.alias || [],
    redirect: Step1jPG4sSb8M4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2ctNPbMn144Meta?.name ?? "events-eventId-apply-components-Step2",
    path: Step2ctNPbMn144Meta?.path ?? "/events/:eventId()/apply/components/Step2",
    meta: Step2ctNPbMn144Meta || {},
    alias: Step2ctNPbMn144Meta?.alias || [],
    redirect: Step2ctNPbMn144Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3l4LMOk34KrMeta?.name ?? "events-eventId-apply-components-Step3",
    path: Step3l4LMOk34KrMeta?.path ?? "/events/:eventId()/apply/components/Step3",
    meta: Step3l4LMOk34KrMeta || {},
    alias: Step3l4LMOk34KrMeta?.alias || [],
    redirect: Step3l4LMOk34KrMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step46A4S4fUlBhMeta?.name ?? "events-eventId-apply-components-Step4",
    path: Step46A4S4fUlBhMeta?.path ?? "/events/:eventId()/apply/components/Step4",
    meta: Step46A4S4fUlBhMeta || {},
    alias: Step46A4S4fUlBhMeta?.alias || [],
    redirect: Step46A4S4fUlBhMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: indexNMdBxu2OZYMeta?.name ?? "events-eventId-apply",
    path: indexNMdBxu2OZYMeta?.path ?? "/events/:eventId()/apply",
    meta: indexNMdBxu2OZYMeta || {},
    alias: indexNMdBxu2OZYMeta?.alias || [],
    redirect: indexNMdBxu2OZYMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/index.vue").then(m => m.default || m)
  },
  {
    name: seo3Mler0gvhiMeta?.name ?? "events-eventId-apply-seo",
    path: seo3Mler0gvhiMeta?.path ?? "/events/:eventId()/apply/seo",
    meta: seo3Mler0gvhiMeta || {},
    alias: seo3Mler0gvhiMeta?.alias || [],
    redirect: seo3Mler0gvhiMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/apply/seo.ts").then(m => m.default || m)
  },
  {
    name: ContentiK3FaPMpoxMeta?.name ?? "events-eventId-components-Content",
    path: ContentiK3FaPMpoxMeta?.path ?? "/events/:eventId()/components/Content",
    meta: ContentiK3FaPMpoxMeta || {},
    alias: ContentiK3FaPMpoxMeta?.alias || [],
    redirect: ContentiK3FaPMpoxMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: FaqHvnV6r60bPMeta?.name ?? "events-eventId-components-Faq",
    path: FaqHvnV6r60bPMeta?.path ?? "/events/:eventId()/components/Faq",
    meta: FaqHvnV6r60bPMeta || {},
    alias: FaqHvnV6r60bPMeta?.alias || [],
    redirect: FaqHvnV6r60bPMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: Side0Y2EYKUMNgMeta?.name ?? "events-eventId-components-Side",
    path: Side0Y2EYKUMNgMeta?.path ?? "/events/:eventId()/components/Side",
    meta: Side0Y2EYKUMNgMeta || {},
    alias: Side0Y2EYKUMNgMeta?.alias || [],
    redirect: Side0Y2EYKUMNgMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Side.vue").then(m => m.default || m)
  },
  {
    name: SideMobileWYoBPYbqZRMeta?.name ?? "events-eventId-components-SideMobile",
    path: SideMobileWYoBPYbqZRMeta?.path ?? "/events/:eventId()/components/SideMobile",
    meta: SideMobileWYoBPYbqZRMeta || {},
    alias: SideMobileWYoBPYbqZRMeta?.alias || [],
    redirect: SideMobileWYoBPYbqZRMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/components/SideMobile.vue").then(m => m.default || m)
  },
  {
    name: SlideUzhNfVecAyMeta?.name ?? "events-eventId-components-Slide",
    path: SlideUzhNfVecAyMeta?.path ?? "/events/:eventId()/components/Slide",
    meta: SlideUzhNfVecAyMeta || {},
    alias: SlideUzhNfVecAyMeta?.alias || [],
    redirect: SlideUzhNfVecAyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: indexhLUVwPvq1fMeta?.name ?? "events-eventId",
    path: indexhLUVwPvq1fMeta?.path ?? "/events/:eventId()",
    meta: indexhLUVwPvq1fMeta || {},
    alias: indexhLUVwPvq1fMeta?.alias || [],
    redirect: indexhLUVwPvq1fMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: seozfj0Gs0okHMeta?.name ?? "events-eventId-seo",
    path: seozfj0Gs0okHMeta?.path ?? "/events/:eventId()/seo",
    meta: seozfj0Gs0okHMeta || {},
    alias: seozfj0Gs0okHMeta?.alias || [],
    redirect: seozfj0Gs0okHMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/[eventId]/seo.ts").then(m => m.default || m)
  },
  {
    name: DropRpuErEnFBMMeta?.name ?? "events-components-Drop",
    path: DropRpuErEnFBMMeta?.path ?? "/events/components/Drop",
    meta: DropRpuErEnFBMMeta || {},
    alias: DropRpuErEnFBMMeta?.alias || [],
    redirect: DropRpuErEnFBMMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/components/Drop.vue").then(m => m.default || m)
  },
  {
    name: indexk6SZ6yfHuzMeta?.name ?? "events",
    path: indexk6SZ6yfHuzMeta?.path ?? "/events",
    meta: indexk6SZ6yfHuzMeta || {},
    alias: indexk6SZ6yfHuzMeta?.alias || [],
    redirect: indexk6SZ6yfHuzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: seoUd5beHDobeMeta?.name ?? "events-seo",
    path: seoUd5beHDobeMeta?.path ?? "/events/seo",
    meta: seoUd5beHDobeMeta || {},
    alias: seoUd5beHDobeMeta?.alias || [],
    redirect: seoUd5beHDobeMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/events/seo.ts").then(m => m.default || m)
  },
  {
    name: indexgvkEU3eN8hMeta?.name ?? "giftchoose-brand-hs-channel-id",
    path: indexgvkEU3eN8hMeta?.path ?? "/giftchoose/brand-hs/channel-:id()",
    meta: indexgvkEU3eN8hMeta || {},
    alias: indexgvkEU3eN8hMeta?.alias || [],
    redirect: indexgvkEU3eN8hMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/channel-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7z5o3j9iUyMeta?.name ?? "giftchoose-brand-hs",
    path: index7z5o3j9iUyMeta?.path ?? "/giftchoose/brand-hs",
    meta: index7z5o3j9iUyMeta || {},
    alias: index7z5o3j9iUyMeta?.alias || [],
    redirect: index7z5o3j9iUyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/index.vue").then(m => m.default || m)
  },
  {
    name: indexoh50DjclujMeta?.name ?? "giftchoose-brand-hs-menu-id",
    path: indexoh50DjclujMeta?.path ?? "/giftchoose/brand-hs/menu-:id()",
    meta: indexoh50DjclujMeta || {},
    alias: indexoh50DjclujMeta?.alias || [],
    redirect: indexoh50DjclujMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftchoose/brand-hs/menu-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: applyoqmO2FoUn6Meta?.name ?? "giftmain-brand-hs-giftid-id-apply",
    path: applyoqmO2FoUn6Meta?.path ?? "/giftmain/brand-hs/giftid-:id()/apply",
    meta: applyoqmO2FoUn6Meta || {},
    alias: applyoqmO2FoUn6Meta?.alias || [],
    redirect: applyoqmO2FoUn6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/apply.vue").then(m => m.default || m)
  },
  {
    name: MobileBottomUVdrHtnYztMeta?.name ?? "giftmain-brand-hs-giftid-id-components-MobileBottom",
    path: MobileBottomUVdrHtnYztMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/MobileBottom",
    meta: MobileBottomUVdrHtnYztMeta || {},
    alias: MobileBottomUVdrHtnYztMeta?.alias || [],
    redirect: MobileBottomUVdrHtnYztMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/MobileBottom.vue").then(m => m.default || m)
  },
  {
    name: StepoAejNn9wbAMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step",
    path: StepoAejNn9wbAMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step",
    meta: StepoAejNn9wbAMeta || {},
    alias: StepoAejNn9wbAMeta?.alias || [],
    redirect: StepoAejNn9wbAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step.vue").then(m => m.default || m)
  },
  {
    name: Step1XzhF9QTBqTMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step1",
    path: Step1XzhF9QTBqTMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step1",
    meta: Step1XzhF9QTBqTMeta || {},
    alias: Step1XzhF9QTBqTMeta?.alias || [],
    redirect: Step1XzhF9QTBqTMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2YST8wc4wKOMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step2",
    path: Step2YST8wc4wKOMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step2",
    meta: Step2YST8wc4wKOMeta || {},
    alias: Step2YST8wc4wKOMeta?.alias || [],
    redirect: Step2YST8wc4wKOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step35MaRkOm9uyMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step3",
    path: Step35MaRkOm9uyMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step3",
    meta: Step35MaRkOm9uyMeta || {},
    alias: Step35MaRkOm9uyMeta?.alias || [],
    redirect: Step35MaRkOm9uyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4XWncW0KA2kMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step4",
    path: Step4XWncW0KA2kMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step4",
    meta: Step4XWncW0KA2kMeta || {},
    alias: Step4XWncW0KA2kMeta?.alias || [],
    redirect: Step4XWncW0KA2kMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: indexHXyIiyiah5Meta?.name ?? "giftmain-brand-hs-giftid-id",
    path: indexHXyIiyiah5Meta?.path ?? "/giftmain/brand-hs/giftid-:id()",
    meta: indexHXyIiyiah5Meta || {},
    alias: indexHXyIiyiah5Meta?.alias || [],
    redirect: indexHXyIiyiah5Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seoizTvFEyFrXMeta?.name ?? "giftmain-brand-hs-giftid-id-seo",
    path: seoizTvFEyFrXMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/seo",
    meta: seoizTvFEyFrXMeta || {},
    alias: seoizTvFEyFrXMeta?.alias || [],
    redirect: seoizTvFEyFrXMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: gsaprZVj6SqfJ4Meta?.name ?? "gsap",
    path: gsaprZVj6SqfJ4Meta?.path ?? "/gsap",
    meta: gsaprZVj6SqfJ4Meta || {},
    alias: gsaprZVj6SqfJ4Meta?.alias || [],
    redirect: gsaprZVj6SqfJ4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/gsap.vue").then(m => m.default || m)
  },
  {
    name: Listxt1NszbzNPMeta?.name ?? "hence-categoryId-components-List",
    path: Listxt1NszbzNPMeta?.path ?? "/hence/:categoryId()/components/List",
    meta: Listxt1NszbzNPMeta || {},
    alias: Listxt1NszbzNPMeta?.alias || [],
    redirect: Listxt1NszbzNPMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/[categoryId]/components/List.vue").then(m => m.default || m)
  },
  {
    name: index99qBcIm4I0Meta?.name ?? "hence-categoryId",
    path: index99qBcIm4I0Meta?.path ?? "/hence/:categoryId()",
    meta: index99qBcIm4I0Meta || {},
    alias: index99qBcIm4I0Meta?.alias || [],
    redirect: index99qBcIm4I0Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: seoHyR8jlaVXUMeta?.name ?? "hence-categoryId-seo",
    path: seoHyR8jlaVXUMeta?.path ?? "/hence/:categoryId()/seo",
    meta: seoHyR8jlaVXUMeta || {},
    alias: seoHyR8jlaVXUMeta?.alias || [],
    redirect: seoHyR8jlaVXUMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/[categoryId]/seo.ts").then(m => m.default || m)
  },
  {
    name: _91id_93C2JPoWCJBzMeta?.name ?? "hence-article-id",
    path: _91id_93C2JPoWCJBzMeta?.path ?? "/hence/article/:id()",
    meta: _91id_93C2JPoWCJBzMeta || {},
    alias: _91id_93C2JPoWCJBzMeta?.alias || [],
    redirect: _91id_93C2JPoWCJBzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/article/[id].vue").then(m => m.default || m)
  },
  {
    name: ContentRicJQfnIgaMeta?.name ?? "hence-article-components-Content",
    path: ContentRicJQfnIgaMeta?.path ?? "/hence/article/components/Content",
    meta: ContentRicJQfnIgaMeta || {},
    alias: ContentRicJQfnIgaMeta?.alias || [],
    redirect: ContentRicJQfnIgaMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/article/components/Content.vue").then(m => m.default || m)
  },
  {
    name: ListSRsLiqDAfAMeta?.name ?? "hence-article-components-List",
    path: ListSRsLiqDAfAMeta?.path ?? "/hence/article/components/List",
    meta: ListSRsLiqDAfAMeta || {},
    alias: ListSRsLiqDAfAMeta?.alias || [],
    redirect: ListSRsLiqDAfAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/article/components/List.vue").then(m => m.default || m)
  },
  {
    name: seoiWuYgHx95WMeta?.name ?? "hence-article-seo",
    path: seoiWuYgHx95WMeta?.path ?? "/hence/article/seo",
    meta: seoiWuYgHx95WMeta || {},
    alias: seoiWuYgHx95WMeta?.alias || [],
    redirect: seoiWuYgHx95WMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/article/seo.ts").then(m => m.default || m)
  },
  {
    name: _91id_93KScV3qeGeWMeta?.name ?? "hence-author-id",
    path: _91id_93KScV3qeGeWMeta?.path ?? "/hence/author/:id()",
    meta: _91id_93KScV3qeGeWMeta || {},
    alias: _91id_93KScV3qeGeWMeta?.alias || [],
    redirect: _91id_93KScV3qeGeWMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/author/[id].vue").then(m => m.default || m)
  },
  {
    name: AuthorGCv7tfgWotMeta?.name ?? "hence-author-components-Author",
    path: AuthorGCv7tfgWotMeta?.path ?? "/hence/author/components/Author",
    meta: AuthorGCv7tfgWotMeta || {},
    alias: AuthorGCv7tfgWotMeta?.alias || [],
    redirect: AuthorGCv7tfgWotMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/author/components/Author.vue").then(m => m.default || m)
  },
  {
    name: Listv9wHxZuEswMeta?.name ?? "hence-author-components-List",
    path: Listv9wHxZuEswMeta?.path ?? "/hence/author/components/List",
    meta: Listv9wHxZuEswMeta || {},
    alias: Listv9wHxZuEswMeta?.alias || [],
    redirect: Listv9wHxZuEswMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/author/components/List.vue").then(m => m.default || m)
  },
  {
    name: seoi2bHGtONEMMeta?.name ?? "hence-author-seo",
    path: seoi2bHGtONEMMeta?.path ?? "/hence/author/seo",
    meta: seoi2bHGtONEMMeta || {},
    alias: seoi2bHGtONEMMeta?.alias || [],
    redirect: seoi2bHGtONEMMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/author/seo.ts").then(m => m.default || m)
  },
  {
    name: AdRL3nc9E4LOMeta?.name ?? "hence-components-Ad",
    path: AdRL3nc9E4LOMeta?.path ?? "/hence/components/Ad",
    meta: AdRL3nc9E4LOMeta || {},
    alias: AdRL3nc9E4LOMeta?.alias || [],
    redirect: AdRL3nc9E4LOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Ad.vue").then(m => m.default || m)
  },
  {
    name: Article4FWV7ixA2kMeta?.name ?? "hence-components-Article",
    path: Article4FWV7ixA2kMeta?.path ?? "/hence/components/Article",
    meta: Article4FWV7ixA2kMeta || {},
    alias: Article4FWV7ixA2kMeta?.alias || [],
    redirect: Article4FWV7ixA2kMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Article.vue").then(m => m.default || m)
  },
  {
    name: AuthorwOh5AGP70gMeta?.name ?? "hence-components-Author",
    path: AuthorwOh5AGP70gMeta?.path ?? "/hence/components/Author",
    meta: AuthorwOh5AGP70gMeta || {},
    alias: AuthorwOh5AGP70gMeta?.alias || [],
    redirect: AuthorwOh5AGP70gMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Author.vue").then(m => m.default || m)
  },
  {
    name: BannerHpIJD80mpbMeta?.name ?? "hence-components-Banner",
    path: BannerHpIJD80mpbMeta?.path ?? "/hence/components/Banner",
    meta: BannerHpIJD80mpbMeta || {},
    alias: BannerHpIJD80mpbMeta?.alias || [],
    redirect: BannerHpIJD80mpbMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: CardItemr8QN5t0qnFMeta?.name ?? "hence-components-CardItem",
    path: CardItemr8QN5t0qnFMeta?.path ?? "/hence/components/CardItem",
    meta: CardItemr8QN5t0qnFMeta || {},
    alias: CardItemr8QN5t0qnFMeta?.alias || [],
    redirect: CardItemr8QN5t0qnFMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/CardItem.vue").then(m => m.default || m)
  },
  {
    name: Experience4t41enA7ENMeta?.name ?? "hence-components-Experience",
    path: Experience4t41enA7ENMeta?.path ?? "/hence/components/Experience",
    meta: Experience4t41enA7ENMeta || {},
    alias: Experience4t41enA7ENMeta?.alias || [],
    redirect: Experience4t41enA7ENMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Experience.vue").then(m => m.default || m)
  },
  {
    name: HenceTextA0QfbzK9nYMeta?.name ?? "hence-components-HenceText",
    path: HenceTextA0QfbzK9nYMeta?.path ?? "/hence/components/HenceText",
    meta: HenceTextA0QfbzK9nYMeta || {},
    alias: HenceTextA0QfbzK9nYMeta?.alias || [],
    redirect: HenceTextA0QfbzK9nYMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/HenceText.vue").then(m => m.default || m)
  },
  {
    name: List1h4yp0jVOgVMeta?.name ?? "hence-components-List1",
    path: List1h4yp0jVOgVMeta?.path ?? "/hence/components/List1",
    meta: List1h4yp0jVOgVMeta || {},
    alias: List1h4yp0jVOgVMeta?.alias || [],
    redirect: List1h4yp0jVOgVMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/List1.vue").then(m => m.default || m)
  },
  {
    name: List2BwjElHFlJxMeta?.name ?? "hence-components-List2",
    path: List2BwjElHFlJxMeta?.path ?? "/hence/components/List2",
    meta: List2BwjElHFlJxMeta || {},
    alias: List2BwjElHFlJxMeta?.alias || [],
    redirect: List2BwjElHFlJxMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/List2.vue").then(m => m.default || m)
  },
  {
    name: List3hgK0WTo119Meta?.name ?? "hence-components-List3",
    path: List3hgK0WTo119Meta?.path ?? "/hence/components/List3",
    meta: List3hgK0WTo119Meta || {},
    alias: List3hgK0WTo119Meta?.alias || [],
    redirect: List3hgK0WTo119Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/List3.vue").then(m => m.default || m)
  },
  {
    name: Newsletterqi4CPBIiucMeta?.name ?? "hence-components-Newsletter",
    path: Newsletterqi4CPBIiucMeta?.path ?? "/hence/components/Newsletter",
    meta: Newsletterqi4CPBIiucMeta || {},
    alias: Newsletterqi4CPBIiucMeta?.alias || [],
    redirect: Newsletterqi4CPBIiucMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Newsletter.vue").then(m => m.default || m)
  },
  {
    name: PageLinkPJD3EW4WpCMeta?.name ?? "hence-components-PageLink",
    path: PageLinkPJD3EW4WpCMeta?.path ?? "/hence/components/PageLink",
    meta: PageLinkPJD3EW4WpCMeta || {},
    alias: PageLinkPJD3EW4WpCMeta?.alias || [],
    redirect: PageLinkPJD3EW4WpCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/PageLink.vue").then(m => m.default || m)
  },
  {
    name: Podcastrj3ywMiwqWMeta?.name ?? "hence-components-Podcast",
    path: Podcastrj3ywMiwqWMeta?.path ?? "/hence/components/Podcast",
    meta: Podcastrj3ywMiwqWMeta || {},
    alias: Podcastrj3ywMiwqWMeta?.alias || [],
    redirect: Podcastrj3ywMiwqWMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Podcast.vue").then(m => m.default || m)
  },
  {
    name: Recipep0cTPgDzQrMeta?.name ?? "hence-components-Recipe",
    path: Recipep0cTPgDzQrMeta?.path ?? "/hence/components/Recipe",
    meta: Recipep0cTPgDzQrMeta || {},
    alias: Recipep0cTPgDzQrMeta?.alias || [],
    redirect: Recipep0cTPgDzQrMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Recipe.vue").then(m => m.default || m)
  },
  {
    name: SidegfLp7kxrvSMeta?.name ?? "hence-components-Side",
    path: SidegfLp7kxrvSMeta?.path ?? "/hence/components/Side",
    meta: SidegfLp7kxrvSMeta || {},
    alias: SidegfLp7kxrvSMeta?.alias || [],
    redirect: SidegfLp7kxrvSMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/components/Side.vue").then(m => m.default || m)
  },
  {
    name: indexaszdXe0eEkMeta?.name ?? "hence",
    path: indexaszdXe0eEkMeta?.path ?? "/hence",
    meta: indexaszdXe0eEkMeta || {},
    alias: indexaszdXe0eEkMeta?.alias || [],
    redirect: indexaszdXe0eEkMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/index.vue").then(m => m.default || m)
  },
  {
    name: indexpv7jICQckeMeta?.name ?? "hence-magazine",
    path: indexpv7jICQckeMeta?.path ?? "/hence/magazine",
    meta: indexpv7jICQckeMeta || {},
    alias: indexpv7jICQckeMeta?.alias || [],
    redirect: indexpv7jICQckeMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: seo7kLWLijvy5Meta?.name ?? "hence-magazine-seo",
    path: seo7kLWLijvy5Meta?.path ?? "/hence/magazine/seo",
    meta: seo7kLWLijvy5Meta || {},
    alias: seo7kLWLijvy5Meta?.alias || [],
    redirect: seo7kLWLijvy5Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/magazine/seo.ts").then(m => m.default || m)
  },
  {
    name: Content8c71KkElMDMeta?.name ?? "hence-recipe-article-id-components-Content",
    path: Content8c71KkElMDMeta?.path ?? "/hence/recipe/article/:id()/components/Content",
    meta: Content8c71KkElMDMeta || {},
    alias: Content8c71KkElMDMeta?.alias || [],
    redirect: Content8c71KkElMDMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: ListtFfGrOy2lxMeta?.name ?? "hence-recipe-article-id-components-List",
    path: ListtFfGrOy2lxMeta?.path ?? "/hence/recipe/article/:id()/components/List",
    meta: ListtFfGrOy2lxMeta || {},
    alias: ListtFfGrOy2lxMeta?.alias || [],
    redirect: ListtFfGrOy2lxMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/List.vue").then(m => m.default || m)
  },
  {
    name: SlideBuVe8Am7YXMeta?.name ?? "hence-recipe-article-id-components-Slide",
    path: SlideBuVe8Am7YXMeta?.path ?? "/hence/recipe/article/:id()/components/Slide",
    meta: SlideBuVe8Am7YXMeta || {},
    alias: SlideBuVe8Am7YXMeta?.alias || [],
    redirect: SlideBuVe8Am7YXMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: indexUOzuphFKwGMeta?.name ?? "hence-recipe-article-id",
    path: indexUOzuphFKwGMeta?.path ?? "/hence/recipe/article/:id()",
    meta: indexUOzuphFKwGMeta || {},
    alias: indexUOzuphFKwGMeta?.alias || [],
    redirect: indexUOzuphFKwGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seor9nE6uh5I3Meta?.name ?? "hence-recipe-article-id-seo",
    path: seor9nE6uh5I3Meta?.path ?? "/hence/recipe/article/:id()/seo",
    meta: seor9nE6uh5I3Meta || {},
    alias: seor9nE6uh5I3Meta?.alias || [],
    redirect: seor9nE6uh5I3Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: FilterYtQLLNiMBRMeta?.name ?? "hence-recipe-components-Filter",
    path: FilterYtQLLNiMBRMeta?.path ?? "/hence/recipe/components/Filter",
    meta: FilterYtQLLNiMBRMeta || {},
    alias: FilterYtQLLNiMBRMeta?.alias || [],
    redirect: FilterYtQLLNiMBRMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/components/Filter.vue").then(m => m.default || m)
  },
  {
    name: ListBw5P8vAMY3Meta?.name ?? "hence-recipe-components-List",
    path: ListBw5P8vAMY3Meta?.path ?? "/hence/recipe/components/List",
    meta: ListBw5P8vAMY3Meta || {},
    alias: ListBw5P8vAMY3Meta?.alias || [],
    redirect: ListBw5P8vAMY3Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/components/List.vue").then(m => m.default || m)
  },
  {
    name: indexEN1bIUl4m8Meta?.name ?? "hence-recipe",
    path: indexEN1bIUl4m8Meta?.path ?? "/hence/recipe",
    meta: indexEN1bIUl4m8Meta || {},
    alias: indexEN1bIUl4m8Meta?.alias || [],
    redirect: indexEN1bIUl4m8Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/index.vue").then(m => m.default || m)
  },
  {
    name: seoHXe5SLksgJMeta?.name ?? "hence-recipe-seo",
    path: seoHXe5SLksgJMeta?.path ?? "/hence/recipe/seo",
    meta: seoHXe5SLksgJMeta || {},
    alias: seoHXe5SLksgJMeta?.alias || [],
    redirect: seoHXe5SLksgJMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/recipe/seo.ts").then(m => m.default || m)
  },
  {
    name: seoHnon6vN51uMeta?.name ?? "hence-seo",
    path: seoHnon6vN51uMeta?.path ?? "/hence/seo",
    meta: seoHnon6vN51uMeta || {},
    alias: seoHnon6vN51uMeta?.alias || [],
    redirect: seoHnon6vN51uMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/seo.ts").then(m => m.default || m)
  },
  {
    name: BannerDpQQJ8a7VGMeta?.name ?? "hence-topic-components-Banner",
    path: BannerDpQQJ8a7VGMeta?.path ?? "/hence/topic/components/Banner",
    meta: BannerDpQQJ8a7VGMeta || {},
    alias: BannerDpQQJ8a7VGMeta?.alias || [],
    redirect: BannerDpQQJ8a7VGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/topic/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: ListBPgCB1WSBLMeta?.name ?? "hence-topic-components-List",
    path: ListBPgCB1WSBLMeta?.path ?? "/hence/topic/components/List",
    meta: ListBPgCB1WSBLMeta || {},
    alias: ListBPgCB1WSBLMeta?.alias || [],
    redirect: ListBPgCB1WSBLMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/topic/components/List.vue").then(m => m.default || m)
  },
  {
    name: indexTq02oS0Zh1Meta?.name ?? "hence-topic",
    path: indexTq02oS0Zh1Meta?.path ?? "/hence/topic",
    meta: indexTq02oS0Zh1Meta || {},
    alias: indexTq02oS0Zh1Meta?.alias || [],
    redirect: indexTq02oS0Zh1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/topic/index.vue").then(m => m.default || m)
  },
  {
    name: seoDjaTw4If5QMeta?.name ?? "hence-topic-seo",
    path: seoDjaTw4If5QMeta?.path ?? "/hence/topic/seo",
    meta: seoDjaTw4If5QMeta || {},
    alias: seoDjaTw4If5QMeta?.alias || [],
    redirect: seoDjaTw4If5QMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/hence/topic/seo.ts").then(m => m.default || m)
  },
  {
    name: AboutxolqhZbZZyMeta?.name ?? "index-components-About",
    path: AboutxolqhZbZZyMeta?.path ?? "//components/About",
    meta: AboutxolqhZbZZyMeta || {},
    alias: AboutxolqhZbZZyMeta?.alias || [],
    redirect: AboutxolqhZbZZyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/About.vue").then(m => m.default || m)
  },
  {
    name: BannerVideothOuTBR6UhMeta?.name ?? "index-components-BannerVideo",
    path: BannerVideothOuTBR6UhMeta?.path ?? "//components/BannerVideo",
    meta: BannerVideothOuTBR6UhMeta || {},
    alias: BannerVideothOuTBR6UhMeta?.alias || [],
    redirect: BannerVideothOuTBR6UhMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/BannerVideo.vue").then(m => m.default || m)
  },
  {
    name: Section1FMQW7UfbjQMeta?.name ?? "index-components-Section1",
    path: Section1FMQW7UfbjQMeta?.path ?? "//components/Section1",
    meta: Section1FMQW7UfbjQMeta || {},
    alias: Section1FMQW7UfbjQMeta?.alias || [],
    redirect: Section1FMQW7UfbjQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2Pwvujo2elfMeta?.name ?? "index-components-Section2",
    path: Section2Pwvujo2elfMeta?.path ?? "//components/Section2",
    meta: Section2Pwvujo2elfMeta || {},
    alias: Section2Pwvujo2elfMeta?.alias || [],
    redirect: Section2Pwvujo2elfMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section2SlideBrui30zHraMeta?.name ?? "index-components-Section2Slide",
    path: Section2SlideBrui30zHraMeta?.path ?? "//components/Section2Slide",
    meta: Section2SlideBrui30zHraMeta || {},
    alias: Section2SlideBrui30zHraMeta?.alias || [],
    redirect: Section2SlideBrui30zHraMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/Section2Slide.vue").then(m => m.default || m)
  },
  {
    name: Section37ADz6dpEO4Meta?.name ?? "index-components-Section3",
    path: Section37ADz6dpEO4Meta?.path ?? "//components/Section3",
    meta: Section37ADz6dpEO4Meta || {},
    alias: Section37ADz6dpEO4Meta?.alias || [],
    redirect: Section37ADz6dpEO4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4CQj2hYD7ATMeta?.name ?? "index-components-Section4",
    path: Section4CQj2hYD7ATMeta?.path ?? "//components/Section4",
    meta: Section4CQj2hYD7ATMeta || {},
    alias: Section4CQj2hYD7ATMeta?.alias || [],
    redirect: Section4CQj2hYD7ATMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: index4OeBWRvavtMeta?.name ?? "index",
    path: index4OeBWRvavtMeta?.path ?? "/",
    meta: index4OeBWRvavtMeta || {},
    alias: index4OeBWRvavtMeta?.alias || [],
    redirect: index4OeBWRvavtMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: seozGzR8Ir2zQMeta?.name ?? "index-seo",
    path: seozGzR8Ir2zQMeta?.path ?? "//seo",
    meta: seozGzR8Ir2zQMeta || {},
    alias: seozGzR8Ir2zQMeta?.alias || [],
    redirect: seozGzR8Ir2zQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/index/seo.ts").then(m => m.default || m)
  },
  {
    name: indexF2rVXBLOCHMeta?.name ?? "locations-locationId",
    path: indexF2rVXBLOCHMeta?.path ?? "/locations/:locationId()",
    meta: indexF2rVXBLOCHMeta || {},
    alias: indexF2rVXBLOCHMeta?.alias || [],
    redirect: indexF2rVXBLOCHMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/locations/[locationId]/index.vue").then(m => m.default || m)
  },
  {
    name: LocationListyEoW1xzbCQMeta?.name ?? "locations-components-LocationList",
    path: LocationListyEoW1xzbCQMeta?.path ?? "/locations/components/LocationList",
    meta: LocationListyEoW1xzbCQMeta || {},
    alias: LocationListyEoW1xzbCQMeta?.alias || [],
    redirect: LocationListyEoW1xzbCQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/locations/components/LocationList.vue").then(m => m.default || m)
  },
  {
    name: LocationPopfQDplY2Cj3Meta?.name ?? "locations-components-LocationPop",
    path: LocationPopfQDplY2Cj3Meta?.path ?? "/locations/components/LocationPop",
    meta: LocationPopfQDplY2Cj3Meta || {},
    alias: LocationPopfQDplY2Cj3Meta?.alias || [],
    redirect: LocationPopfQDplY2Cj3Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/locations/components/LocationPop.vue").then(m => m.default || m)
  },
  {
    name: indexGi2ndYqV4cMeta?.name ?? "locations",
    path: indexGi2ndYqV4cMeta?.path ?? "/locations",
    meta: indexGi2ndYqV4cMeta || {},
    alias: indexGi2ndYqV4cMeta?.alias || [],
    redirect: indexGi2ndYqV4cMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: seo2DT7QrksX0Meta?.name ?? "locations-seo",
    path: seo2DT7QrksX0Meta?.path ?? "/locations/seo",
    meta: seo2DT7QrksX0Meta || {},
    alias: seo2DT7QrksX0Meta?.alias || [],
    redirect: seo2DT7QrksX0Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/locations/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1gYzcuJjQp2Meta?.name ?? "member-activity-components-Section1",
    path: Section1gYzcuJjQp2Meta?.path ?? "/member/activity/components/Section1",
    meta: Section1gYzcuJjQp2Meta || {},
    alias: Section1gYzcuJjQp2Meta?.alias || [],
    redirect: Section1gYzcuJjQp2Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2BtkvqDOX6cMeta?.name ?? "member-activity-components-Section2",
    path: Section2BtkvqDOX6cMeta?.path ?? "/member/activity/components/Section2",
    meta: Section2BtkvqDOX6cMeta || {},
    alias: Section2BtkvqDOX6cMeta?.alias || [],
    redirect: Section2BtkvqDOX6cMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section32UaULMYZKMMeta?.name ?? "member-activity-components-Section3",
    path: Section32UaULMYZKMMeta?.path ?? "/member/activity/components/Section3",
    meta: Section32UaULMYZKMMeta || {},
    alias: Section32UaULMYZKMMeta?.alias || [],
    redirect: Section32UaULMYZKMMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: _91no_93CNzaziChUiMeta?.name ?? "member-activity-credit-note-no",
    path: _91no_93CNzaziChUiMeta?.path ?? "/member/activity/credit-note/:no()",
    meta: _91no_93CNzaziChUiMeta || {},
    alias: _91no_93CNzaziChUiMeta?.alias || [],
    redirect: _91no_93CNzaziChUiMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/credit-note/[no].vue").then(m => m.default || m)
  },
  {
    name: indexo5WBo8ax13Meta?.name ?? "member-activity",
    path: indexo5WBo8ax13Meta?.path ?? "/member/activity",
    meta: indexo5WBo8ax13Meta || {},
    alias: indexo5WBo8ax13Meta?.alias || [],
    redirect: indexo5WBo8ax13Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/index.vue").then(m => m.default || m)
  },
  {
    name: seo7RAmU5LvXtMeta?.name ?? "member-activity-seo",
    path: seo7RAmU5LvXtMeta?.path ?? "/member/activity/seo",
    meta: seo7RAmU5LvXtMeta || {},
    alias: seo7RAmU5LvXtMeta?.alias || [],
    redirect: seo7RAmU5LvXtMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/activity/seo.ts").then(m => m.default || m)
  },
  {
    name: PopupContentcRcUMcJUj4Meta?.name ?? "member-components-PopupContent",
    path: PopupContentcRcUMcJUj4Meta?.path ?? "/member/components/PopupContent",
    meta: PopupContentcRcUMcJUj4Meta || {},
    alias: PopupContentcRcUMcJUj4Meta?.alias || [],
    redirect: PopupContentcRcUMcJUj4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/components/PopupContent.vue").then(m => m.default || m)
  },
  {
    name: SideMenuV8oaY4GZfEMeta?.name ?? "member-components-SideMenu",
    path: SideMenuV8oaY4GZfEMeta?.path ?? "/member/components/SideMenu",
    meta: SideMenuV8oaY4GZfEMeta || {},
    alias: SideMenuV8oaY4GZfEMeta?.alias || [],
    redirect: SideMenuV8oaY4GZfEMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/components/SideMenu.vue").then(m => m.default || m)
  },
  {
    name: indexg5Ztp8jyMdMeta?.name ?? "member-edit",
    path: indexg5Ztp8jyMdMeta?.path ?? "/member/edit",
    meta: indexg5Ztp8jyMdMeta || {},
    alias: indexg5Ztp8jyMdMeta?.alias || [],
    redirect: indexg5Ztp8jyMdMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/edit/index.vue").then(m => m.default || m)
  },
  {
    name: seoI731Q6JBwyMeta?.name ?? "member-edit-seo",
    path: seoI731Q6JBwyMeta?.path ?? "/member/edit/seo",
    meta: seoI731Q6JBwyMeta || {},
    alias: seoI731Q6JBwyMeta?.alias || [],
    redirect: seoI731Q6JBwyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/edit/seo.ts").then(m => m.default || m)
  },
  {
    name: indexuMRZFyBkAAMeta?.name ?? "member",
    path: indexuMRZFyBkAAMeta?.path ?? "/member",
    meta: indexuMRZFyBkAAMeta || {},
    alias: indexuMRZFyBkAAMeta?.alias || [],
    redirect: indexuMRZFyBkAAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/index.vue").then(m => m.default || m)
  },
  {
    name: _91no_93AInVoN9yEAMeta?.name ?? "member-refund-no",
    path: _91no_93AInVoN9yEAMeta?.path ?? "/member/refund/:no()",
    meta: _91no_93AInVoN9yEAMeta || {},
    alias: _91no_93AInVoN9yEAMeta?.alias || [],
    redirect: _91no_93AInVoN9yEAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/refund/[no].vue").then(m => m.default || m)
  },
  {
    name: Step1L3RmwVxRJwMeta?.name ?? "member-refund-components-Step1",
    path: Step1L3RmwVxRJwMeta?.path ?? "/member/refund/components/Step1",
    meta: Step1L3RmwVxRJwMeta || {},
    alias: Step1L3RmwVxRJwMeta?.alias || [],
    redirect: Step1L3RmwVxRJwMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/refund/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2zIc1DAGaL6Meta?.name ?? "member-refund-components-Step2",
    path: Step2zIc1DAGaL6Meta?.path ?? "/member/refund/components/Step2",
    meta: Step2zIc1DAGaL6Meta || {},
    alias: Step2zIc1DAGaL6Meta?.alias || [],
    redirect: Step2zIc1DAGaL6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/refund/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3aX1sO3Qf4hMeta?.name ?? "member-refund-components-Step3",
    path: Step3aX1sO3Qf4hMeta?.path ?? "/member/refund/components/Step3",
    meta: Step3aX1sO3Qf4hMeta || {},
    alias: Step3aX1sO3Qf4hMeta?.alias || [],
    redirect: Step3aX1sO3Qf4hMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/refund/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: seoOpXrzqcuBSMeta?.name ?? "member-refund-seo",
    path: seoOpXrzqcuBSMeta?.path ?? "/member/refund/seo",
    meta: seoOpXrzqcuBSMeta || {},
    alias: seoOpXrzqcuBSMeta?.alias || [],
    redirect: seoOpXrzqcuBSMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/refund/seo.ts").then(m => m.default || m)
  },
  {
    name: seooC0PLiRv5kMeta?.name ?? "member-seo",
    path: seooC0PLiRv5kMeta?.path ?? "/member/seo",
    meta: seooC0PLiRv5kMeta || {},
    alias: seooC0PLiRv5kMeta?.alias || [],
    redirect: seooC0PLiRv5kMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1SLeFDuUKppMeta?.name ?? "member-service-components-Section1",
    path: Section1SLeFDuUKppMeta?.path ?? "/member/service/components/Section1",
    meta: Section1SLeFDuUKppMeta || {},
    alias: Section1SLeFDuUKppMeta?.alias || [],
    redirect: Section1SLeFDuUKppMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section20LArBBBpeSMeta?.name ?? "member-service-components-Section2",
    path: Section20LArBBBpeSMeta?.path ?? "/member/service/components/Section2",
    meta: Section20LArBBBpeSMeta || {},
    alias: Section20LArBBBpeSMeta?.alias || [],
    redirect: Section20LArBBBpeSMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3hoHHx2XXy6Meta?.name ?? "member-service-components-Section3",
    path: Section3hoHHx2XXy6Meta?.path ?? "/member/service/components/Section3",
    meta: Section3hoHHx2XXy6Meta || {},
    alias: Section3hoHHx2XXy6Meta?.alias || [],
    redirect: Section3hoHHx2XXy6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: _91no_93oUVOQCIyKNMeta?.name ?? "member-service-credit-note-no",
    path: _91no_93oUVOQCIyKNMeta?.path ?? "/member/service/credit-note/:no()",
    meta: _91no_93oUVOQCIyKNMeta || {},
    alias: _91no_93oUVOQCIyKNMeta?.alias || [],
    redirect: _91no_93oUVOQCIyKNMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/credit-note/[no].vue").then(m => m.default || m)
  },
  {
    name: indexA5HGTP9NdcMeta?.name ?? "member-service",
    path: indexA5HGTP9NdcMeta?.path ?? "/member/service",
    meta: indexA5HGTP9NdcMeta || {},
    alias: indexA5HGTP9NdcMeta?.alias || [],
    redirect: indexA5HGTP9NdcMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/index.vue").then(m => m.default || m)
  },
  {
    name: seolxeRlmu3bIMeta?.name ?? "member-service-seo",
    path: seolxeRlmu3bIMeta?.path ?? "/member/service/seo",
    meta: seolxeRlmu3bIMeta || {},
    alias: seolxeRlmu3bIMeta?.alias || [],
    redirect: seolxeRlmu3bIMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/member/service/seo.ts").then(m => m.default || m)
  },
  {
    name: membership_45termscJJzY6kiAHMeta?.name ?? "membership-terms",
    path: membership_45termscJJzY6kiAHMeta?.path ?? "/membership-terms",
    meta: membership_45termscJJzY6kiAHMeta || {},
    alias: membership_45termscJJzY6kiAHMeta?.alias || [],
    redirect: membership_45termscJJzY6kiAHMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership-terms.vue").then(m => m.default || m)
  },
  {
    name: FaqAYIad4f9C5Meta?.name ?? "membership-components-Faq",
    path: FaqAYIad4f9C5Meta?.path ?? "/membership/components/Faq",
    meta: FaqAYIad4f9C5Meta || {},
    alias: FaqAYIad4f9C5Meta?.alias || [],
    redirect: FaqAYIad4f9C5Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: Section1yiaPah5wkUMeta?.name ?? "membership-components-Section1",
    path: Section1yiaPah5wkUMeta?.path ?? "/membership/components/Section1",
    meta: Section1yiaPah5wkUMeta || {},
    alias: Section1yiaPah5wkUMeta?.alias || [],
    redirect: Section1yiaPah5wkUMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2xmrW4BV3zXMeta?.name ?? "membership-components-Section2",
    path: Section2xmrW4BV3zXMeta?.path ?? "/membership/components/Section2",
    meta: Section2xmrW4BV3zXMeta || {},
    alias: Section2xmrW4BV3zXMeta?.alias || [],
    redirect: Section2xmrW4BV3zXMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3UeeqmIpi7oMeta?.name ?? "membership-components-Section3",
    path: Section3UeeqmIpi7oMeta?.path ?? "/membership/components/Section3",
    meta: Section3UeeqmIpi7oMeta || {},
    alias: Section3UeeqmIpi7oMeta?.alias || [],
    redirect: Section3UeeqmIpi7oMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section3ListoSBM27EdiDMeta?.name ?? "membership-components-Section3List",
    path: Section3ListoSBM27EdiDMeta?.path ?? "/membership/components/Section3List",
    meta: Section3ListoSBM27EdiDMeta || {},
    alias: Section3ListoSBM27EdiDMeta?.alias || [],
    redirect: Section3ListoSBM27EdiDMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/components/Section3List.vue").then(m => m.default || m)
  },
  {
    name: index8ndW8IZwDpMeta?.name ?? "membership",
    path: index8ndW8IZwDpMeta?.path ?? "/membership",
    meta: index8ndW8IZwDpMeta || {},
    alias: index8ndW8IZwDpMeta?.alias || [],
    redirect: index8ndW8IZwDpMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: seohGF9TwZefcMeta?.name ?? "membership-seo",
    path: seohGF9TwZefcMeta?.path ?? "/membership/seo",
    meta: seohGF9TwZefcMeta || {},
    alias: seohGF9TwZefcMeta?.alias || [],
    redirect: seohGF9TwZefcMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/membership/seo.ts").then(m => m.default || m)
  },
  {
    name: index49Ky4L3x7VMeta?.name ?? "news-slug",
    path: index49Ky4L3x7VMeta?.path ?? "/news/:slug()",
    meta: index49Ky4L3x7VMeta || {},
    alias: index49Ky4L3x7VMeta?.alias || [],
    redirect: index49Ky4L3x7VMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsId_93g231vJQKnNMeta?.name ?? "news-article-newsId",
    path: _91newsId_93g231vJQKnNMeta?.path ?? "/news/article/:newsId()",
    meta: _91newsId_93g231vJQKnNMeta || {},
    alias: _91newsId_93g231vJQKnNMeta?.alias || [],
    redirect: _91newsId_93g231vJQKnNMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/article/[newsId].vue").then(m => m.default || m)
  },
  {
    name: seolVuOUZmao2Meta?.name ?? "news-article-seo",
    path: seolVuOUZmao2Meta?.path ?? "/news/article/seo",
    meta: seolVuOUZmao2Meta || {},
    alias: seolVuOUZmao2Meta?.alias || [],
    redirect: seolVuOUZmao2Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/article/seo.ts").then(m => m.default || m)
  },
  {
    name: ClassLink0Am53Ns0E3Meta?.name ?? "news-components-ClassLink",
    path: ClassLink0Am53Ns0E3Meta?.path ?? "/news/components/ClassLink",
    meta: ClassLink0Am53Ns0E3Meta || {},
    alias: ClassLink0Am53Ns0E3Meta?.alias || [],
    redirect: ClassLink0Am53Ns0E3Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: ListvBszIYGlouMeta?.name ?? "news-components-List",
    path: ListvBszIYGlouMeta?.path ?? "/news/components/List",
    meta: ListvBszIYGlouMeta || {},
    alias: ListvBszIYGlouMeta?.alias || [],
    redirect: ListvBszIYGlouMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/components/List.vue").then(m => m.default || m)
  },
  {
    name: NewsItem7JzJ3JiFjzMeta?.name ?? "news-components-NewsItem",
    path: NewsItem7JzJ3JiFjzMeta?.path ?? "/news/components/NewsItem",
    meta: NewsItem7JzJ3JiFjzMeta || {},
    alias: NewsItem7JzJ3JiFjzMeta?.alias || [],
    redirect: NewsItem7JzJ3JiFjzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/components/NewsItem.vue").then(m => m.default || m)
  },
  {
    name: NewsPinjodrhxUcSOMeta?.name ?? "news-components-NewsPin",
    path: NewsPinjodrhxUcSOMeta?.path ?? "/news/components/NewsPin",
    meta: NewsPinjodrhxUcSOMeta || {},
    alias: NewsPinjodrhxUcSOMeta?.alias || [],
    redirect: NewsPinjodrhxUcSOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/components/NewsPin.vue").then(m => m.default || m)
  },
  {
    name: indexLgh22nqDS6Meta?.name ?? "news",
    path: indexLgh22nqDS6Meta?.path ?? "/news",
    meta: indexLgh22nqDS6Meta || {},
    alias: indexLgh22nqDS6Meta?.alias || [],
    redirect: indexLgh22nqDS6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: seozZGajAwTPOMeta?.name ?? "news-seo",
    path: seozZGajAwTPOMeta?.path ?? "/news/seo",
    meta: seozZGajAwTPOMeta || {},
    alias: seozZGajAwTPOMeta?.alias || [],
    redirect: seozZGajAwTPOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/news/seo.ts").then(m => m.default || m)
  },
  {
    name: privacy_45policyNAtsemJ0rbMeta?.name ?? "privacy-policy",
    path: privacy_45policyNAtsemJ0rbMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyNAtsemJ0rbMeta || {},
    alias: privacy_45policyNAtsemJ0rbMeta?.alias || [],
    redirect: privacy_45policyNAtsemJ0rbMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: qrcodel4KhG1bgs2Meta?.name ?? "qrcode",
    path: qrcodel4KhG1bgs2Meta?.path ?? "/qrcode",
    meta: qrcodel4KhG1bgs2Meta || {},
    alias: qrcodel4KhG1bgs2Meta?.alias || [],
    redirect: qrcodel4KhG1bgs2Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/qrcode.vue").then(m => m.default || m)
  },
  {
    name: scrollKTFH0qEzlmMeta?.name ?? "scroll",
    path: scrollKTFH0qEzlmMeta?.path ?? "/scroll",
    meta: scrollKTFH0qEzlmMeta || {},
    alias: scrollKTFH0qEzlmMeta?.alias || [],
    redirect: scrollKTFH0qEzlmMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/scroll.vue").then(m => m.default || m)
  },
  {
    name: searchnFfBLBMS1KMeta?.name ?? "search",
    path: searchnFfBLBMS1KMeta?.path ?? "/search",
    meta: searchnFfBLBMS1KMeta || {},
    alias: searchnFfBLBMS1KMeta?.alias || [],
    redirect: searchnFfBLBMS1KMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: seo_searchIDS5MxfSrqMeta?.name ?? "seo_search",
    path: seo_searchIDS5MxfSrqMeta?.path ?? "/seo_search",
    meta: seo_searchIDS5MxfSrqMeta || {},
    alias: seo_searchIDS5MxfSrqMeta?.alias || [],
    redirect: seo_searchIDS5MxfSrqMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/seo_search.ts").then(m => m.default || m)
  },
  {
    name: FeaturesU2fOGx1FVwMeta?.name ?? "services-cleaning-components-Features",
    path: FeaturesU2fOGx1FVwMeta?.path ?? "/services/cleaning/components/Features",
    meta: FeaturesU2fOGx1FVwMeta || {},
    alias: FeaturesU2fOGx1FVwMeta?.alias || [],
    redirect: FeaturesU2fOGx1FVwMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Step1793erbL77CMeta?.name ?? "services-cleaning-components-Step1",
    path: Step1793erbL77CMeta?.path ?? "/services/cleaning/components/Step1",
    meta: Step1793erbL77CMeta || {},
    alias: Step1793erbL77CMeta?.alias || [],
    redirect: Step1793erbL77CMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2n9OsWDMMARMeta?.name ?? "services-cleaning-components-Step2",
    path: Step2n9OsWDMMARMeta?.path ?? "/services/cleaning/components/Step2",
    meta: Step2n9OsWDMMARMeta || {},
    alias: Step2n9OsWDMMARMeta?.alias || [],
    redirect: Step2n9OsWDMMARMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3mpYQ8cFlKIMeta?.name ?? "services-cleaning-components-Step3",
    path: Step3mpYQ8cFlKIMeta?.path ?? "/services/cleaning/components/Step3",
    meta: Step3mpYQ8cFlKIMeta || {},
    alias: Step3mpYQ8cFlKIMeta?.alias || [],
    redirect: Step3mpYQ8cFlKIMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4H84C0OtEgrMeta?.name ?? "services-cleaning-components-Step4",
    path: Step4H84C0OtEgrMeta?.path ?? "/services/cleaning/components/Step4",
    meta: Step4H84C0OtEgrMeta || {},
    alias: Step4H84C0OtEgrMeta?.alias || [],
    redirect: Step4H84C0OtEgrMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seo7QPXjGqNX9Meta?.name ?? "services-cleaning-form_seo",
    path: form_seo7QPXjGqNX9Meta?.path ?? "/services/cleaning/form_seo",
    meta: form_seo7QPXjGqNX9Meta || {},
    alias: form_seo7QPXjGqNX9Meta?.alias || [],
    redirect: form_seo7QPXjGqNX9Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formaHgjYsEldCMeta?.name ?? "services-cleaning-form",
    path: formaHgjYsEldCMeta?.path ?? "/services/cleaning/form",
    meta: formaHgjYsEldCMeta || {},
    alias: formaHgjYsEldCMeta?.alias || [],
    redirect: formaHgjYsEldCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/form.vue").then(m => m.default || m)
  },
  {
    name: indexVMkHHFJMQmMeta?.name ?? "services-cleaning",
    path: indexVMkHHFJMQmMeta?.path ?? "/services/cleaning",
    meta: indexVMkHHFJMQmMeta || {},
    alias: indexVMkHHFJMQmMeta?.alias || [],
    redirect: indexVMkHHFJMQmMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/index.vue").then(m => m.default || m)
  },
  {
    name: seohOWeaSyFG7Meta?.name ?? "services-cleaning-seo",
    path: seohOWeaSyFG7Meta?.path ?? "/services/cleaning/seo",
    meta: seohOWeaSyFG7Meta || {},
    alias: seohOWeaSyFG7Meta?.alias || [],
    redirect: seohOWeaSyFG7Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/cleaning/seo.ts").then(m => m.default || m)
  },
  {
    name: Bottomd5y5vw2sx2Meta?.name ?? "services-components-Bottom",
    path: Bottomd5y5vw2sx2Meta?.path ?? "/services/components/Bottom",
    meta: Bottomd5y5vw2sx2Meta || {},
    alias: Bottomd5y5vw2sx2Meta?.alias || [],
    redirect: Bottomd5y5vw2sx2Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Bottom.vue").then(m => m.default || m)
  },
  {
    name: CategoryD8wD3aY1QGMeta?.name ?? "services-components-Category",
    path: CategoryD8wD3aY1QGMeta?.path ?? "/services/components/Category",
    meta: CategoryD8wD3aY1QGMeta || {},
    alias: CategoryD8wD3aY1QGMeta?.alias || [],
    redirect: CategoryD8wD3aY1QGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryItemwwjWcLbHO1Meta?.name ?? "services-components-CategoryItem",
    path: CategoryItemwwjWcLbHO1Meta?.path ?? "/services/components/CategoryItem",
    meta: CategoryItemwwjWcLbHO1Meta || {},
    alias: CategoryItemwwjWcLbHO1Meta?.alias || [],
    redirect: CategoryItemwwjWcLbHO1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/CategoryItem.vue").then(m => m.default || m)
  },
  {
    name: ContactInfod42uvBq0c9Meta?.name ?? "services-components-ContactInfo",
    path: ContactInfod42uvBq0c9Meta?.path ?? "/services/components/ContactInfo",
    meta: ContactInfod42uvBq0c9Meta || {},
    alias: ContactInfod42uvBq0c9Meta?.alias || [],
    redirect: ContactInfod42uvBq0c9Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/ContactInfo.vue").then(m => m.default || m)
  },
  {
    name: FaqzXTAZ53bZnMeta?.name ?? "services-components-Faq",
    path: FaqzXTAZ53bZnMeta?.path ?? "/services/components/Faq",
    meta: FaqzXTAZ53bZnMeta || {},
    alias: FaqzXTAZ53bZnMeta?.alias || [],
    redirect: FaqzXTAZ53bZnMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: FeaturesyoGwuLwwy6Meta?.name ?? "services-components-Features",
    path: FeaturesyoGwuLwwy6Meta?.path ?? "/services/components/Features",
    meta: FeaturesyoGwuLwwy6Meta || {},
    alias: FeaturesyoGwuLwwy6Meta?.alias || [],
    redirect: FeaturesyoGwuLwwy6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Features.vue").then(m => m.default || m)
  },
  {
    name: OtherdojKP2LfyCMeta?.name ?? "services-components-Other",
    path: OtherdojKP2LfyCMeta?.path ?? "/services/components/Other",
    meta: OtherdojKP2LfyCMeta || {},
    alias: OtherdojKP2LfyCMeta?.alias || [],
    redirect: OtherdojKP2LfyCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Other.vue").then(m => m.default || m)
  },
  {
    name: ProcessMrePrpoerDMeta?.name ?? "services-components-Process",
    path: ProcessMrePrpoerDMeta?.path ?? "/services/components/Process",
    meta: ProcessMrePrpoerDMeta || {},
    alias: ProcessMrePrpoerDMeta?.alias || [],
    redirect: ProcessMrePrpoerDMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Process.vue").then(m => m.default || m)
  },
  {
    name: Review8RUqIjGHCOMeta?.name ?? "services-components-Review",
    path: Review8RUqIjGHCOMeta?.path ?? "/services/components/Review",
    meta: Review8RUqIjGHCOMeta || {},
    alias: Review8RUqIjGHCOMeta?.alias || [],
    redirect: Review8RUqIjGHCOMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/components/Review.vue").then(m => m.default || m)
  },
  {
    name: Relatedf8UH7zHRKvMeta?.name ?? "services-faq-article-id-components-Related",
    path: Relatedf8UH7zHRKvMeta?.path ?? "/services/faq/article/:id()/components/Related",
    meta: Relatedf8UH7zHRKvMeta || {},
    alias: Relatedf8UH7zHRKvMeta?.alias || [],
    redirect: Relatedf8UH7zHRKvMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/components/Related.vue").then(m => m.default || m)
  },
  {
    name: indexzIcyrkU8AIMeta?.name ?? "services-faq-article-id",
    path: indexzIcyrkU8AIMeta?.path ?? "/services/faq/article/:id()",
    meta: indexzIcyrkU8AIMeta || {},
    alias: indexzIcyrkU8AIMeta?.alias || [],
    redirect: indexzIcyrkU8AIMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seoUTp8z8Se3NMeta?.name ?? "services-faq-article-id-seo",
    path: seoUTp8z8Se3NMeta?.path ?? "/services/faq/article/:id()/seo",
    meta: seoUTp8z8Se3NMeta || {},
    alias: seoUTp8z8Se3NMeta?.alias || [],
    redirect: seoUTp8z8Se3NMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: ContactBRGtSvQCnDMeta?.name ?? "services-faq-components-Contact",
    path: ContactBRGtSvQCnDMeta?.path ?? "/services/faq/components/Contact",
    meta: ContactBRGtSvQCnDMeta || {},
    alias: ContactBRGtSvQCnDMeta?.alias || [],
    redirect: ContactBRGtSvQCnDMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: FaqItemypJ7BXK5zwMeta?.name ?? "services-faq-components-FaqItem",
    path: FaqItemypJ7BXK5zwMeta?.path ?? "/services/faq/components/FaqItem",
    meta: FaqItemypJ7BXK5zwMeta || {},
    alias: FaqItemypJ7BXK5zwMeta?.alias || [],
    redirect: FaqItemypJ7BXK5zwMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/components/FaqItem.vue").then(m => m.default || m)
  },
  {
    name: indexo1YoMcPDlQMeta?.name ?? "services-faq",
    path: indexo1YoMcPDlQMeta?.path ?? "/services/faq",
    meta: indexo1YoMcPDlQMeta || {},
    alias: indexo1YoMcPDlQMeta?.alias || [],
    redirect: indexo1YoMcPDlQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/index.vue").then(m => m.default || m)
  },
  {
    name: seos9OFMsT3MGMeta?.name ?? "services-faq-seo",
    path: seos9OFMsT3MGMeta?.path ?? "/services/faq/seo",
    meta: seos9OFMsT3MGMeta || {},
    alias: seos9OFMsT3MGMeta?.alias || [],
    redirect: seos9OFMsT3MGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/faq/seo.ts").then(m => m.default || m)
  },
  {
    name: ContactInfoLBAyDFJMS1Meta?.name ?? "services-fast-repair-components-ContactInfo",
    path: ContactInfoLBAyDFJMS1Meta?.path ?? "/services/fast-repair/components/ContactInfo",
    meta: ContactInfoLBAyDFJMS1Meta || {},
    alias: ContactInfoLBAyDFJMS1Meta?.alias || [],
    redirect: ContactInfoLBAyDFJMS1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/components/ContactInfo.vue").then(m => m.default || m)
  },
  {
    name: Step1sIcGGCG02FMeta?.name ?? "services-fast-repair-components-Step1",
    path: Step1sIcGGCG02FMeta?.path ?? "/services/fast-repair/components/Step1",
    meta: Step1sIcGGCG02FMeta || {},
    alias: Step1sIcGGCG02FMeta?.alias || [],
    redirect: Step1sIcGGCG02FMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step22577LEisTFMeta?.name ?? "services-fast-repair-components-Step2",
    path: Step22577LEisTFMeta?.path ?? "/services/fast-repair/components/Step2",
    meta: Step22577LEisTFMeta || {},
    alias: Step22577LEisTFMeta?.alias || [],
    redirect: Step22577LEisTFMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3Y3sJVAmKU4Meta?.name ?? "services-fast-repair-components-Step3",
    path: Step3Y3sJVAmKU4Meta?.path ?? "/services/fast-repair/components/Step3",
    meta: Step3Y3sJVAmKU4Meta || {},
    alias: Step3Y3sJVAmKU4Meta?.alias || [],
    redirect: Step3Y3sJVAmKU4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4c49pIEOL95Meta?.name ?? "services-fast-repair-components-Step4",
    path: Step4c49pIEOL95Meta?.path ?? "/services/fast-repair/components/Step4",
    meta: Step4c49pIEOL95Meta || {},
    alias: Step4c49pIEOL95Meta?.alias || [],
    redirect: Step4c49pIEOL95Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seokSd8F77ynqMeta?.name ?? "services-fast-repair-form_seo",
    path: form_seokSd8F77ynqMeta?.path ?? "/services/fast-repair/form_seo",
    meta: form_seokSd8F77ynqMeta || {},
    alias: form_seokSd8F77ynqMeta?.alias || [],
    redirect: form_seokSd8F77ynqMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formARnBlMRjbVMeta?.name ?? "services-fast-repair-form",
    path: formARnBlMRjbVMeta?.path ?? "/services/fast-repair/form",
    meta: formARnBlMRjbVMeta || {},
    alias: formARnBlMRjbVMeta?.alias || [],
    redirect: formARnBlMRjbVMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/form.vue").then(m => m.default || m)
  },
  {
    name: indexoCtutQ8t8LMeta?.name ?? "services-fast-repair",
    path: indexoCtutQ8t8LMeta?.path ?? "/services/fast-repair",
    meta: indexoCtutQ8t8LMeta || {},
    alias: indexoCtutQ8t8LMeta?.alias || [],
    redirect: indexoCtutQ8t8LMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/index.vue").then(m => m.default || m)
  },
  {
    name: seo3cslucX07uMeta?.name ?? "services-fast-repair-seo",
    path: seo3cslucX07uMeta?.path ?? "/services/fast-repair/seo",
    meta: seo3cslucX07uMeta || {},
    alias: seo3cslucX07uMeta?.alias || [],
    redirect: seo3cslucX07uMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/fast-repair/seo.ts").then(m => m.default || m)
  },
  {
    name: indexE7bhYqzJRZMeta?.name ?? "services",
    path: indexE7bhYqzJRZMeta?.path ?? "/services",
    meta: indexE7bhYqzJRZMeta || {},
    alias: indexE7bhYqzJRZMeta?.alias || [],
    redirect: indexE7bhYqzJRZMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: Step1YaFBXU8Y2eMeta?.name ?? "services-inhome-components-Step1",
    path: Step1YaFBXU8Y2eMeta?.path ?? "/services/inhome/components/Step1",
    meta: Step1YaFBXU8Y2eMeta || {},
    alias: Step1YaFBXU8Y2eMeta?.alias || [],
    redirect: Step1YaFBXU8Y2eMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2IMfYLpgmD6Meta?.name ?? "services-inhome-components-Step2",
    path: Step2IMfYLpgmD6Meta?.path ?? "/services/inhome/components/Step2",
    meta: Step2IMfYLpgmD6Meta || {},
    alias: Step2IMfYLpgmD6Meta?.alias || [],
    redirect: Step2IMfYLpgmD6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: form_seoTFpbPzLYBVMeta?.name ?? "services-inhome-form_seo",
    path: form_seoTFpbPzLYBVMeta?.path ?? "/services/inhome/form_seo",
    meta: form_seoTFpbPzLYBVMeta || {},
    alias: form_seoTFpbPzLYBVMeta?.alias || [],
    redirect: form_seoTFpbPzLYBVMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formcwEsSmvKr1Meta?.name ?? "services-inhome-form",
    path: formcwEsSmvKr1Meta?.path ?? "/services/inhome/form",
    meta: formcwEsSmvKr1Meta || {},
    alias: formcwEsSmvKr1Meta?.alias || [],
    redirect: formcwEsSmvKr1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/form.vue").then(m => m.default || m)
  },
  {
    name: index7HdiW7KwBCMeta?.name ?? "services-inhome",
    path: index7HdiW7KwBCMeta?.path ?? "/services/inhome",
    meta: index7HdiW7KwBCMeta || {},
    alias: index7HdiW7KwBCMeta?.alias || [],
    redirect: index7HdiW7KwBCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/index.vue").then(m => m.default || m)
  },
  {
    name: seoDHAbCytt5FMeta?.name ?? "services-inhome-seo",
    path: seoDHAbCytt5FMeta?.path ?? "/services/inhome/seo",
    meta: seoDHAbCytt5FMeta || {},
    alias: seoDHAbCytt5FMeta?.alias || [],
    redirect: seoDHAbCytt5FMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/inhome/seo.ts").then(m => m.default || m)
  },
  {
    name: Fast4hW1tQoIRqMeta?.name ?? "services-repair-components-Fast",
    path: Fast4hW1tQoIRqMeta?.path ?? "/services/repair/components/Fast",
    meta: Fast4hW1tQoIRqMeta || {},
    alias: Fast4hW1tQoIRqMeta?.alias || [],
    redirect: Fast4hW1tQoIRqMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/components/Fast.vue").then(m => m.default || m)
  },
  {
    name: Step1DdwFIPfanUMeta?.name ?? "services-repair-components-Step1",
    path: Step1DdwFIPfanUMeta?.path ?? "/services/repair/components/Step1",
    meta: Step1DdwFIPfanUMeta || {},
    alias: Step1DdwFIPfanUMeta?.alias || [],
    redirect: Step1DdwFIPfanUMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2Ky77Y0wSXxMeta?.name ?? "services-repair-components-Step2",
    path: Step2Ky77Y0wSXxMeta?.path ?? "/services/repair/components/Step2",
    meta: Step2Ky77Y0wSXxMeta || {},
    alias: Step2Ky77Y0wSXxMeta?.alias || [],
    redirect: Step2Ky77Y0wSXxMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3cEWvGyI99oMeta?.name ?? "services-repair-components-Step3",
    path: Step3cEWvGyI99oMeta?.path ?? "/services/repair/components/Step3",
    meta: Step3cEWvGyI99oMeta || {},
    alias: Step3cEWvGyI99oMeta?.alias || [],
    redirect: Step3cEWvGyI99oMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4r7lfqTNrsGMeta?.name ?? "services-repair-components-Step4",
    path: Step4r7lfqTNrsGMeta?.path ?? "/services/repair/components/Step4",
    meta: Step4r7lfqTNrsGMeta || {},
    alias: Step4r7lfqTNrsGMeta?.alias || [],
    redirect: Step4r7lfqTNrsGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seoZIaCXGo282Meta?.name ?? "services-repair-form_seo",
    path: form_seoZIaCXGo282Meta?.path ?? "/services/repair/form_seo",
    meta: form_seoZIaCXGo282Meta || {},
    alias: form_seoZIaCXGo282Meta?.alias || [],
    redirect: form_seoZIaCXGo282Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/form_seo.ts").then(m => m.default || m)
  },
  {
    name: form6JoTyaMYklMeta?.name ?? "services-repair-form",
    path: form6JoTyaMYklMeta?.path ?? "/services/repair/form",
    meta: form6JoTyaMYklMeta || {},
    alias: form6JoTyaMYklMeta?.alias || [],
    redirect: form6JoTyaMYklMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/form.vue").then(m => m.default || m)
  },
  {
    name: indexN37owyhlGLMeta?.name ?? "services-repair",
    path: indexN37owyhlGLMeta?.path ?? "/services/repair",
    meta: indexN37owyhlGLMeta || {},
    alias: indexN37owyhlGLMeta?.alias || [],
    redirect: indexN37owyhlGLMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/index.vue").then(m => m.default || m)
  },
  {
    name: seoOSxBceCemjMeta?.name ?? "services-repair-seo",
    path: seoOSxBceCemjMeta?.path ?? "/services/repair/seo",
    meta: seoOSxBceCemjMeta || {},
    alias: seoOSxBceCemjMeta?.alias || [],
    redirect: seoOSxBceCemjMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/repair/seo.ts").then(m => m.default || m)
  },
  {
    name: indexq3Ux3BBbklMeta?.name ?? "services-select",
    path: indexq3Ux3BBbklMeta?.path ?? "/services/select",
    meta: indexq3Ux3BBbklMeta || {},
    alias: indexq3Ux3BBbklMeta?.alias || [],
    redirect: indexq3Ux3BBbklMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/select/index.vue").then(m => m.default || m)
  },
  {
    name: seoGGPF1cOPKyMeta?.name ?? "services-select-seo",
    path: seoGGPF1cOPKyMeta?.path ?? "/services/select/seo",
    meta: seoGGPF1cOPKyMeta || {},
    alias: seoGGPF1cOPKyMeta?.alias || [],
    redirect: seoGGPF1cOPKyMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/select/seo.ts").then(m => m.default || m)
  },
  {
    name: seoipsymbKdK1Meta?.name ?? "services-seo",
    path: seoipsymbKdK1Meta?.path ?? "/services/seo",
    meta: seoipsymbKdK1Meta || {},
    alias: seoipsymbKdK1Meta?.alias || [],
    redirect: seoipsymbKdK1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/seo.ts").then(m => m.default || m)
  },
  {
    name: DirectStep1P4Z2oFUoKiMeta?.name ?? "services-warranty-components-DirectStep1",
    path: DirectStep1P4Z2oFUoKiMeta?.path ?? "/services/warranty/components/DirectStep1",
    meta: DirectStep1P4Z2oFUoKiMeta || {},
    alias: DirectStep1P4Z2oFUoKiMeta?.alias || [],
    redirect: DirectStep1P4Z2oFUoKiMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/DirectStep1.vue").then(m => m.default || m)
  },
  {
    name: DirectStep2HQp08dLI26Meta?.name ?? "services-warranty-components-DirectStep2",
    path: DirectStep2HQp08dLI26Meta?.path ?? "/services/warranty/components/DirectStep2",
    meta: DirectStep2HQp08dLI26Meta || {},
    alias: DirectStep2HQp08dLI26Meta?.alias || [],
    redirect: DirectStep2HQp08dLI26Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/DirectStep2.vue").then(m => m.default || m)
  },
  {
    name: Step1xogYvwvzjQMeta?.name ?? "services-warranty-components-Step1",
    path: Step1xogYvwvzjQMeta?.path ?? "/services/warranty/components/Step1",
    meta: Step1xogYvwvzjQMeta || {},
    alias: Step1xogYvwvzjQMeta?.alias || [],
    redirect: Step1xogYvwvzjQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2SdHuJqxRxiMeta?.name ?? "services-warranty-components-Step2",
    path: Step2SdHuJqxRxiMeta?.path ?? "/services/warranty/components/Step2",
    meta: Step2SdHuJqxRxiMeta || {},
    alias: Step2SdHuJqxRxiMeta?.alias || [],
    redirect: Step2SdHuJqxRxiMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step33SBjHj1ZdzMeta?.name ?? "services-warranty-components-Step3",
    path: Step33SBjHj1ZdzMeta?.path ?? "/services/warranty/components/Step3",
    meta: Step33SBjHj1ZdzMeta || {},
    alias: Step33SBjHj1ZdzMeta?.alias || [],
    redirect: Step33SBjHj1ZdzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4GCJ1p35eZzMeta?.name ?? "services-warranty-components-Step4",
    path: Step4GCJ1p35eZzMeta?.path ?? "/services/warranty/components/Step4",
    meta: Step4GCJ1p35eZzMeta || {},
    alias: Step4GCJ1p35eZzMeta?.alias || [],
    redirect: Step4GCJ1p35eZzMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: directMgl2IKOXufMeta?.name ?? "services-warranty-direct",
    path: directMgl2IKOXufMeta?.path ?? "/services/warranty/direct",
    meta: directMgl2IKOXufMeta || {},
    alias: directMgl2IKOXufMeta?.alias || [],
    redirect: directMgl2IKOXufMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/direct.vue").then(m => m.default || m)
  },
  {
    name: form_seoSOBFZA0WsCMeta?.name ?? "services-warranty-form_seo",
    path: form_seoSOBFZA0WsCMeta?.path ?? "/services/warranty/form_seo",
    meta: form_seoSOBFZA0WsCMeta || {},
    alias: form_seoSOBFZA0WsCMeta?.alias || [],
    redirect: form_seoSOBFZA0WsCMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formk8ixqdjBtJMeta?.name ?? "services-warranty-form",
    path: formk8ixqdjBtJMeta?.path ?? "/services/warranty/form",
    meta: formk8ixqdjBtJMeta || {},
    alias: formk8ixqdjBtJMeta?.alias || [],
    redirect: formk8ixqdjBtJMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/form.vue").then(m => m.default || m)
  },
  {
    name: indexuGuSoSC81GMeta?.name ?? "services-warranty",
    path: indexuGuSoSC81GMeta?.path ?? "/services/warranty",
    meta: indexuGuSoSC81GMeta || {},
    alias: indexuGuSoSC81GMeta?.alias || [],
    redirect: indexuGuSoSC81GMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: seo0zB4SVVJnBMeta?.name ?? "services-warranty-seo",
    path: seo0zB4SVVJnBMeta?.path ?? "/services/warranty/seo",
    meta: seo0zB4SVVJnBMeta || {},
    alias: seo0zB4SVVJnBMeta?.alias || [],
    redirect: seo0zB4SVVJnBMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/services/warranty/seo.ts").then(m => m.default || m)
  },
  {
    name: sitemapifJR7TB0dJMeta?.name ?? "sitemap",
    path: sitemapifJR7TB0dJMeta?.path ?? "/sitemap",
    meta: sitemapifJR7TB0dJMeta || {},
    alias: sitemapifJR7TB0dJMeta?.alias || [],
    redirect: sitemapifJR7TB0dJMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91subId_93obiZHmdWTKMeta?.name ?? "sustainability-mainId-subId",
    path: _91subId_93obiZHmdWTKMeta?.path ?? "/sustainability/:mainId()/:subId()",
    meta: _91subId_93obiZHmdWTKMeta || {},
    alias: _91subId_93obiZHmdWTKMeta?.alias || [],
    redirect: _91subId_93obiZHmdWTKMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/[subId].vue").then(m => m.default || m)
  },
  {
    name: BannercQNgpeQ560Meta?.name ?? "sustainability-mainId-components-Banner",
    path: BannercQNgpeQ560Meta?.path ?? "/sustainability/:mainId()/components/Banner",
    meta: BannercQNgpeQ560Meta || {},
    alias: BannercQNgpeQ560Meta?.alias || [],
    redirect: BannercQNgpeQ560Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: ListsdNeQsWFNRGMeta?.name ?? "sustainability-mainId-components-Lists",
    path: ListsdNeQsWFNRGMeta?.path ?? "/sustainability/:mainId()/components/Lists",
    meta: ListsdNeQsWFNRGMeta || {},
    alias: ListsdNeQsWFNRGMeta?.alias || [],
    redirect: ListsdNeQsWFNRGMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: indexcSgkBr3APfMeta?.name ?? "sustainability-mainId",
    path: indexcSgkBr3APfMeta?.path ?? "/sustainability/:mainId()",
    meta: indexcSgkBr3APfMeta || {},
    alias: indexcSgkBr3APfMeta?.alias || [],
    redirect: indexcSgkBr3APfMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/index.vue").then(m => m.default || m)
  },
  {
    name: seoYTg5bM8Ua2Meta?.name ?? "sustainability-mainId-seo",
    path: seoYTg5bM8Ua2Meta?.path ?? "/sustainability/:mainId()/seo",
    meta: seoYTg5bM8Ua2Meta || {},
    alias: seoYTg5bM8Ua2Meta?.alias || [],
    redirect: seoYTg5bM8Ua2Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/[mainId]/seo.ts").then(m => m.default || m)
  },
  {
    name: ListsqIaosyQayPMeta?.name ?? "sustainability-article-id-components-Lists",
    path: ListsqIaosyQayPMeta?.path ?? "/sustainability/article/:id()/components/Lists",
    meta: ListsqIaosyQayPMeta || {},
    alias: ListsqIaosyQayPMeta?.alias || [],
    redirect: ListsqIaosyQayPMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: indexrYtzSlkngQMeta?.name ?? "sustainability-article-id",
    path: indexrYtzSlkngQMeta?.path ?? "/sustainability/article/:id()",
    meta: indexrYtzSlkngQMeta || {},
    alias: indexrYtzSlkngQMeta?.alias || [],
    redirect: indexrYtzSlkngQMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seoZnnCqcLvVVMeta?.name ?? "sustainability-article-id-seo",
    path: seoZnnCqcLvVVMeta?.path ?? "/sustainability/article/:id()/seo",
    meta: seoZnnCqcLvVVMeta || {},
    alias: seoZnnCqcLvVVMeta?.alias || [],
    redirect: seoZnnCqcLvVVMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1q3WNIH6xPvMeta?.name ?? "sustainability-components-Section1",
    path: Section1q3WNIH6xPvMeta?.path ?? "/sustainability/components/Section1",
    meta: Section1q3WNIH6xPvMeta || {},
    alias: Section1q3WNIH6xPvMeta?.alias || [],
    redirect: Section1q3WNIH6xPvMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2wGyNijzP6qMeta?.name ?? "sustainability-components-Section2",
    path: Section2wGyNijzP6qMeta?.path ?? "/sustainability/components/Section2",
    meta: Section2wGyNijzP6qMeta || {},
    alias: Section2wGyNijzP6qMeta?.alias || [],
    redirect: Section2wGyNijzP6qMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3fePhaCRqZnMeta?.name ?? "sustainability-components-Section3",
    path: Section3fePhaCRqZnMeta?.path ?? "/sustainability/components/Section3",
    meta: Section3fePhaCRqZnMeta || {},
    alias: Section3fePhaCRqZnMeta?.alias || [],
    redirect: Section3fePhaCRqZnMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section49ELsOksjnvMeta?.name ?? "sustainability-components-Section4",
    path: Section49ELsOksjnvMeta?.path ?? "/sustainability/components/Section4",
    meta: Section49ELsOksjnvMeta || {},
    alias: Section49ELsOksjnvMeta?.alias || [],
    redirect: Section49ELsOksjnvMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section5ZtmIfZFuUFMeta?.name ?? "sustainability-components-Section5",
    path: Section5ZtmIfZFuUFMeta?.path ?? "/sustainability/components/Section5",
    meta: Section5ZtmIfZFuUFMeta || {},
    alias: Section5ZtmIfZFuUFMeta?.alias || [],
    redirect: Section5ZtmIfZFuUFMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: Section6uytKUV4lAvMeta?.name ?? "sustainability-components-Section6",
    path: Section6uytKUV4lAvMeta?.path ?? "/sustainability/components/Section6",
    meta: Section6uytKUV4lAvMeta || {},
    alias: Section6uytKUV4lAvMeta?.alias || [],
    redirect: Section6uytKUV4lAvMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section6.vue").then(m => m.default || m)
  },
  {
    name: Section7zQzbV5RAmIMeta?.name ?? "sustainability-components-Section7",
    path: Section7zQzbV5RAmIMeta?.path ?? "/sustainability/components/Section7",
    meta: Section7zQzbV5RAmIMeta || {},
    alias: Section7zQzbV5RAmIMeta?.alias || [],
    redirect: Section7zQzbV5RAmIMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section7.vue").then(m => m.default || m)
  },
  {
    name: Section8rNNINj0Tf1Meta?.name ?? "sustainability-components-Section8",
    path: Section8rNNINj0Tf1Meta?.path ?? "/sustainability/components/Section8",
    meta: Section8rNNINj0Tf1Meta || {},
    alias: Section8rNNINj0Tf1Meta?.alias || [],
    redirect: Section8rNNINj0Tf1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section8.vue").then(m => m.default || m)
  },
  {
    name: Section9cSwgICskSAMeta?.name ?? "sustainability-components-Section9",
    path: Section9cSwgICskSAMeta?.path ?? "/sustainability/components/Section9",
    meta: Section9cSwgICskSAMeta || {},
    alias: Section9cSwgICskSAMeta?.alias || [],
    redirect: Section9cSwgICskSAMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Section9.vue").then(m => m.default || m)
  },
  {
    name: SideGddjiG2FYPMeta?.name ?? "sustainability-components-Side",
    path: SideGddjiG2FYPMeta?.path ?? "/sustainability/components/Side",
    meta: SideGddjiG2FYPMeta || {},
    alias: SideGddjiG2FYPMeta?.alias || [],
    redirect: SideGddjiG2FYPMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/components/Side.vue").then(m => m.default || m)
  },
  {
    name: indexb6ZyNyxxn6Meta?.name ?? "sustainability",
    path: indexb6ZyNyxxn6Meta?.path ?? "/sustainability",
    meta: indexb6ZyNyxxn6Meta || {},
    alias: indexb6ZyNyxxn6Meta?.alias || [],
    redirect: indexb6ZyNyxxn6Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: seok3GtbcI5m3Meta?.name ?? "sustainability-seo",
    path: seok3GtbcI5m3Meta?.path ?? "/sustainability/seo",
    meta: seok3GtbcI5m3Meta || {},
    alias: seok3GtbcI5m3Meta?.alias || [],
    redirect: seok3GtbcI5m3Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/sustainability/seo.ts").then(m => m.default || m)
  },
  {
    name: Section132nhYw3FHbMeta?.name ?? "talent-development-components-Section1",
    path: Section132nhYw3FHbMeta?.path ?? "/talent-development/components/Section1",
    meta: Section132nhYw3FHbMeta || {},
    alias: Section132nhYw3FHbMeta?.alias || [],
    redirect: Section132nhYw3FHbMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2a7OYJ8uKXvMeta?.name ?? "talent-development-components-Section2",
    path: Section2a7OYJ8uKXvMeta?.path ?? "/talent-development/components/Section2",
    meta: Section2a7OYJ8uKXvMeta || {},
    alias: Section2a7OYJ8uKXvMeta?.alias || [],
    redirect: Section2a7OYJ8uKXvMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3ERbOjIO9seMeta?.name ?? "talent-development-components-Section3",
    path: Section3ERbOjIO9seMeta?.path ?? "/talent-development/components/Section3",
    meta: Section3ERbOjIO9seMeta || {},
    alias: Section3ERbOjIO9seMeta?.alias || [],
    redirect: Section3ERbOjIO9seMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4NJbKSwcie4Meta?.name ?? "talent-development-components-Section4",
    path: Section4NJbKSwcie4Meta?.path ?? "/talent-development/components/Section4",
    meta: Section4NJbKSwcie4Meta || {},
    alias: Section4NJbKSwcie4Meta?.alias || [],
    redirect: Section4NJbKSwcie4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section5NFmfEMJmp1Meta?.name ?? "talent-development-components-Section5",
    path: Section5NFmfEMJmp1Meta?.path ?? "/talent-development/components/Section5",
    meta: Section5NFmfEMJmp1Meta || {},
    alias: Section5NFmfEMJmp1Meta?.alias || [],
    redirect: Section5NFmfEMJmp1Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: indexktty8EAzGNMeta?.name ?? "talent-development",
    path: indexktty8EAzGNMeta?.path ?? "/talent-development",
    meta: indexktty8EAzGNMeta || {},
    alias: indexktty8EAzGNMeta?.alias || [],
    redirect: indexktty8EAzGNMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/index.vue").then(m => m.default || m)
  },
  {
    name: seowWTotBBwucMeta?.name ?? "talent-development-seo",
    path: seowWTotBBwucMeta?.path ?? "/talent-development/seo",
    meta: seowWTotBBwucMeta || {},
    alias: seowWTotBBwucMeta?.alias || [],
    redirect: seowWTotBBwucMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/talent-development/seo.ts").then(m => m.default || m)
  },
  {
    name: BreadcrumbSaAzGnPRduMeta?.name ?? "zonezone-components-Breadcrumb",
    path: BreadcrumbSaAzGnPRduMeta?.path ?? "/zonezone/components/Breadcrumb",
    meta: BreadcrumbSaAzGnPRduMeta || {},
    alias: BreadcrumbSaAzGnPRduMeta?.alias || [],
    redirect: BreadcrumbSaAzGnPRduMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Breadcrumb.vue").then(m => m.default || m)
  },
  {
    name: Section1u8jf3D4vKTMeta?.name ?? "zonezone-components-Section1",
    path: Section1u8jf3D4vKTMeta?.path ?? "/zonezone/components/Section1",
    meta: Section1u8jf3D4vKTMeta || {},
    alias: Section1u8jf3D4vKTMeta?.alias || [],
    redirect: Section1u8jf3D4vKTMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2TANFsVBQG0Meta?.name ?? "zonezone-components-Section2",
    path: Section2TANFsVBQG0Meta?.path ?? "/zonezone/components/Section2",
    meta: Section2TANFsVBQG0Meta || {},
    alias: Section2TANFsVBQG0Meta?.alias || [],
    redirect: Section2TANFsVBQG0Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3JDVsaVcwMmMeta?.name ?? "zonezone-components-Section3",
    path: Section3JDVsaVcwMmMeta?.path ?? "/zonezone/components/Section3",
    meta: Section3JDVsaVcwMmMeta || {},
    alias: Section3JDVsaVcwMmMeta?.alias || [],
    redirect: Section3JDVsaVcwMmMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4Ztk0N6YFmLMeta?.name ?? "zonezone-components-Section4",
    path: Section4Ztk0N6YFmLMeta?.path ?? "/zonezone/components/Section4",
    meta: Section4Ztk0N6YFmLMeta || {},
    alias: Section4Ztk0N6YFmLMeta?.alias || [],
    redirect: Section4Ztk0N6YFmLMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section5p7xzhXdfIJMeta?.name ?? "zonezone-components-Section5",
    path: Section5p7xzhXdfIJMeta?.path ?? "/zonezone/components/Section5",
    meta: Section5p7xzhXdfIJMeta || {},
    alias: Section5p7xzhXdfIJMeta?.alias || [],
    redirect: Section5p7xzhXdfIJMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: Section6MZPBd9YxBbMeta?.name ?? "zonezone-components-Section6",
    path: Section6MZPBd9YxBbMeta?.path ?? "/zonezone/components/Section6",
    meta: Section6MZPBd9YxBbMeta || {},
    alias: Section6MZPBd9YxBbMeta?.alias || [],
    redirect: Section6MZPBd9YxBbMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/components/Section6.vue").then(m => m.default || m)
  },
  {
    name: indexAI1bQzRyJ4Meta?.name ?? "zonezone",
    path: indexAI1bQzRyJ4Meta?.path ?? "/zonezone",
    meta: indexAI1bQzRyJ4Meta || {},
    alias: indexAI1bQzRyJ4Meta?.alias || [],
    redirect: indexAI1bQzRyJ4Meta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/index.vue").then(m => m.default || m)
  },
  {
    name: seopDANcarTHnMeta?.name ?? "zonezone-seo",
    path: seopDANcarTHnMeta?.path ?? "/zonezone/seo",
    meta: seopDANcarTHnMeta || {},
    alias: seopDANcarTHnMeta?.alias || [],
    redirect: seopDANcarTHnMeta?.redirect || undefined,
    component: () => import("/home/fable/hengstyle-frontend/pages/zonezone/seo.ts").then(m => m.default || m)
  }
]