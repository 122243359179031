import revive_payload_client_4sVQNw7RlN from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/fable/hengstyle-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/fable/hengstyle-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/fable/hengstyle-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/fable/hengstyle-frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/home/fable/hengstyle-frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/fable/hengstyle-frontend/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/fable/hengstyle-frontend/.nuxt/element-plus-injection.plugin.mjs";
import plugin_iGLjBbor9q from "/home/fable/hengstyle-frontend/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/home/fable/hengstyle-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/fable/hengstyle-frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import aos_client_rDSYU13oOX from "/home/fable/hengstyle-frontend/plugins/aos.client.ts";
import client_refresh_client_0jLhlD2tkT from "/home/fable/hengstyle-frontend/plugins/client-refresh.client.ts";
import google_recaptcha_rYK9Kziv68 from "/home/fable/hengstyle-frontend/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_tbFNToZNim,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_iGLjBbor9q,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  aos_client_rDSYU13oOX,
  client_refresh_client_0jLhlD2tkT,
  google_recaptcha_rYK9Kziv68
]